import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AppFacade } from "@app/app.facade";
import { pageEvent } from "@app/helpers/utag.helpers";
import { IAccountLowBalance, ILowBalance } from "@app/models/accounts";
import { IAccounts } from "@app/models/all-data";
import { AlertsTypes } from "@app/modules/shared/models/alert-message.enum";
import { AuthenticationService } from "@app/modules/shared/services/authentication.service";
import { VehiclesService } from "@app/modules/vehicles/services/vehicle.service";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { AccountModalityTypes } from "../../models/account-modality.enum";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";
import { AccountNavigationService } from "../../services/account-navigation-service.service";
import { AccountRequestsService } from "../../services/account-requests/account-requests.service";
import {
  maxValue,
  minValue,
  onlyDigitsAndDots,
} from "../../shared/utils/validators.utils";
import { AccountFacade } from "../../store/facade/account.facade";

@Component({
  selector: "fp-automatic-recharge-config",
  templateUrl: "./automatic-recharge-config.component.html",
  styleUrls: ["./automatic-recharge-config.component.scss"],
})
export class AutomaticRechargeConfigComponent implements OnInit, OnDestroy {
  /** Selected modality */
  formAutomaticRechargeConfig: UntypedFormGroup;
  /** Destroy observables */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Alerts types enum */
  alertsTypes = AlertsTypes;
  /** Min recharge value */
  maxRechargeValue = 99999999;
  /** Account data */
  minRecharge = 0;
  /** Recharge value */
  rechargeValue: string;
  /** Active recharge value */
  activeRechargeValue: string;
  /* Categories values */
  categoriesValues = [
    6000,
    7000,
    8000,
    10000,
    12000,
    14000,
    24000,
    34000,
    38100,
  ];
  /** Account data */
  accountData: IAccounts;

  /** Automatic recharge data */
  automaticRechargeData: IAutomaticRecharge;
  /** Sending data */
  sendingData = false;
  /** Updating data flag */
  updating = false;

  accountId: IAccounts;

  lowBalances: IAccountLowBalance[] = [];

  /**
   * Constructor
   * @param appFacade
   * @param translate
   * @param authenticationService
   * @param accountNavigationService
   */
  constructor(
    private appFacade: AppFacade,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private accountNavigationService: AccountNavigationService,
    private accountFacade: AccountFacade,
    private vehiclesService: VehiclesService,
    private accountRequestsService: AccountRequestsService,
  ) {
    this.getAccount();
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    this.getAccount();
    this.initForm();
    this.accountData = this.accountId;
    await this.getLowBalance();
    this.getUserEmail();
  }

  /**
   * Init form
   */
  async initForm(): Promise<void> {
    this.formAutomaticRechargeConfig = new UntypedFormGroup({
      rechargeValue: new UntypedFormControl("", [
        Validators.required,
        onlyDigitsAndDots(),
        maxValue(this.maxRechargeValue),
      ]),
      activeRechargeValue: new UntypedFormControl("", [
        Validators.required,
        onlyDigitsAndDots(),
        minValue(1),
        maxValue(this.maxRechargeValue),
      ]),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(
          "^([a-zA-Z0-9-+_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,3})$",
        ),
      ]),
    });
  }

  /**
   * Set account data
   */
  setAccountData(accountData: IAccounts): void {
    this.accountData = accountData;
    this.updating = this.accountData?.financialProduct
      ? Object.keys(this.accountData?.financialProduct).length > 0
      : false;
    this.setInitFormValues();
  }

  /**
   * method to get accounts
   */
  getAccount(): void {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IAccounts) => {
        this.accountId = data;
        this.accountData = data;
      });
  }

  async getLowBalance(): Promise<any> {
    const promiseLowBalance = await this.selectLowBalance$
      .pipe(take(1))
      .toPromise();
    this.lowBalances = promiseLowBalance.data;
  }

  /**
   * Set initial form values
   */
  async setInitFormValues(): Promise<void> {
    this.automaticRechargeData = await this.selectAutomaticRecharge$
      .pipe(take(1))
      .toPromise();

    this.formAutomaticRechargeConfig
      .get("rechargeValue")
      .setValue(
        this.automaticRechargeData.rechargeValue ??
          this.accountData?.financialProduct.rechargeBalance,
      );

    this.formAutomaticRechargeConfig
      .get("activeRechargeValue")
      .setValue(
        this.automaticRechargeData.activeRechargeValue ??
          this.accountData?.financialProduct.lowBalance,
      );

    const useName = await this.authenticationService.getUsername();
    this.vehiclesService.getAllVehicles(useName)?.subscribe((result) => {
      const vehicles = result.data.map((data) => data.vehicle);
      vehicles.sort(
        (a, b) => Number(b.categoryValue) - Number(a.categoryValue),
      );
      this.minRecharge = vehicles[0]
        ? this.categoriesValues[Number(vehicles[0]?.categoryValue) - 1] * 2
        : 0;
      this.minRecharge = this.minRecharge > 30000 ? this.minRecharge : 30000;
      this.formAutomaticRechargeConfig
        .get("rechargeValue")
        .addValidators(minValue(this.minRecharge));

      this.formAutomaticRechargeConfig
        .get("rechargeValue")
        .updateValueAndValidity();
    });
  }

  /**
   * Get user email
   */
  async getUserEmail(): Promise<any> {
    if (!this.lowBalances) {
      this.formAutomaticRechargeConfig.controls.email.setValue("");
      return;
    }
    const email = this.lowBalances.find(
      (lowBalance) =>
        lowBalance.accountId == Number(this.accountData?.accountId),
    );

    this.formAutomaticRechargeConfig.controls.email.setValue(
      email?.emailNotification || "",
    );
  }

  /**
   * Set data form config
   */
  async setDataFormConfig() {
    if (this.formAutomaticRechargeConfig.valid) {
      const activeRechargeValue = this.formAutomaticRechargeConfig
        .get("activeRechargeValue")
        .value.replace(/\./g, "");
      const rechargeValue = this.formAutomaticRechargeConfig
        .get("rechargeValue")
        .value.replace(/\./g, "");
      const email = this.formAutomaticRechargeConfig.get("email").value;
      this.accountFacade.setAutomaticRecharge({
        activeRechargeValue,
        rechargeValue,
        email,
        getAllCards: false,
        fromRecharge: false,
      });
      if (this.updating) {
        this.sendingData = true;
        const payload = {
          modality: AccountModalityTypes.AUTOMATIC,
          accountId: this.accountData?.accountId.toString(),
          activeRechargeValue,
          rechargeValue,
          refCard: "",
        };

        const request = await this.accountRequestsService.changeModality(
          payload,
          this.updating,
        );
        request.pipe(takeUntil(this.destroy$)).subscribe({
          next: (response) => {
            pageEvent(
              "click-btn-save-automatic-recharge-config",
              "/account/automatic-recharge-config",
              "Automatic recharge configuration saved",
            );
            this.accountFacade.setAutomaticRecharge({
              activeRechargeValue,
              rechargeValue,
              email,
              referenceCard: response.data.idPaymentMethod,
              brandCard: response.data.brand,
              cardMask: response.data.cardMask,
              getAllCards: false,
              fromRecharge: false,
            });
            this.accountNavigationService.next(5);
          },
          error: () => {
            this.sendingData = false;
          },
        });
      } else {
        // Tagging click event
        pageEvent(
          "click-btn-continue-automatic-recharge-config",
          "/account/automatic-recharge-config",
          "Automatic recharge configuration continue",
        );
        this.accountNavigationService.next(4);
      }
    }
  }
  /**
   * Remove letters
   * @param input
   */
  removeLetters(input: string, event?: InputEvent) {
    const value = this.formAutomaticRechargeConfig.get(input)?.value || "";
    const onlyNumbers =
      event?.data === "." || value === "00"
        ? value.slice(0, -1)
        : value.replace(/[^\d.]/g, "").slice(0, 11);
    this.formAutomaticRechargeConfig.get(input)?.setValue(onlyNumbers);
  }

  /**
   * Get select language
   * @readonly
   * @type {Observable<Languages>}
   * @memberof AutomaticRechargeConfigComponent
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Select account modality
   * @readonly
   * @type {Observable<IAutomaticRecharge>}
   * @memberof AutomaticRechargeConfigComponent
   */
  get selectAutomaticRecharge$(): Observable<IAutomaticRecharge> {
    return this.accountFacade.selectAutomaticRecharge$;
  }

  get selectLowBalance$(): Observable<ILowBalance> {
    return this.appFacade.selectLowBalance$;
  }

  /**
   * Select account selected
   * @readonly
   * @type {Observable<IAccounts>}
   * @memberof AdminMenuComponent
   */
  get selectAccountSelected$(): Observable<IAccounts> {
    return this.appFacade.selectAccountSelected$;
  }
}
