// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  hmr: false,
  production: false,
  stg: true,
  form: {
    moneyPartialBack: {
      account: {
        min: 4,
        max: 50,
      },
    },
  },
  endpoints: {
    agreements: {
      init: "/agreements",
    },
    accounts: {
      init: "/accounts",
      specific: {
        availableAccounts: "/get_available_accounts",
        rechargeFinancialProduct: "/recharge_financial_product",
        financialProducts: "/get_financial_products",
        associateProduct: "/associate_financial_product",
        createRefund: "/create_refund",
        createRefundPartial: "/create_refund_partial",
        updateRefundPartial: "/update_refund_partial",
        create: "/create",
        movementRefund: "/movement_refund",
      },
    },
    account: {
      init: "/account",
      specific: {
        assignModality: "/assign-modality",
        changeModality: "/change-modality",
      },
    },
    prospect: {
      init: "/prospects",
      specific: {
        identificationTypes: "/identificationtypes",
        validate: "/validate",
        documents: "/documents",
        habeasdata: "/habeasdata",
        termsandconditions: "/enterprise/termsandconditions",
        account: "/account",
      },
    },
    vehicles: {
      init: "/vehicles",
      init2: "/vehicle",
      get: "/get",
      v2: "/v2",
      specific: {
        categories: "/categories",
        create: "",
        delete: "/delete",
        validate: "/validate",
        update: "/update-vehicle-details",
      },
    },
    workflows: {
      init: "/workflows",
      specific: {
        vehicleEnt: "/vehicle-ent",
        create: "/create",
      },
    },
    commons: {
      init: "/commons",
      specific: {
        cities: "/cities",
      },
    },
    vinculate: {
      init: "/vinculate_enterprise",
    },
    dashboard: {
      init: "/dashboard?pageSizeVehicles=3&pageSizeMovements=3",
    },
    session: {
      init: "/session",
      specific: {
        login: "/login",
        logout: "/logout",
        refresh: "/refresh",
      },
    },
    movementsDashboard: {
      init: "/movements_dashboard",
    },
    autoenrollment: {
      init: "/autoenrollment",
      specific: {
        tag: "/tag",
      },
    },
    usersession: {
      init: "/usersession",
      specific: {
        signup: "/signup",
        login: "/login",
        refresh: "/refresh",
        logout: "/logout",
        forgotpassword: "/forgotpassword",
        confirmforgotpassword: "/confirmforgotpassword",
        confirm: "/confirm",
        resendconfirmationcode: "/resendconfirmationcode",
        admindeleteuser: "/admindeleteuser",
      },
    },
    admin: {
      init: "/admin",
      specific: { user: "/user", user_update: "/user_update" },
    },
    roles: {
      init: "/roles",
    },
    password: {
      init: "/password",
      specific: { validate: "/validate" },
    },
    customer_consultation: {
      init: "/customer_consultation",
    },
    user_customer: {
      init: "/user_customer",
    },
    tags: {
      init: "/tags",
      specific: {
        termsAndConditions: "/terms_and_conditions",
        types: "/types",
        shippingAddress: "/shipping_address",
        reservation: "/reservation",
        confirmation: "/confirmation",
        active: "/active",
        vehicle_tag: "/vehicle-tag",
        tag_no_cost: "/tag_no_cost",
        dissociation: "/dissociation",
      },
    },
    pse: {
      init: "/pse",
      specific: {
        banks: "/banks",
        transaction: "/transaction",
        statusTransaction: "/get/transaction?url=",
      },
    },
    core: {
      init: "/core",
      tags: "/tags",
      specific: {
        dissation_tag: "/tags/dissociation",
        consultReport: "/consultReport",
        vehicles: "/vehicles",
        customerOtp: "/customer/generate-otp",
        validateOtp: "/customer/validate-otp",
        customer: "/customer/details",
        get: "/get-user-data",
        update: "/update-user-data",
        autoenrollment: "/autoenrollment",
        dissociation: "/dissociation",
        activation: "/activation",
        replace: "/replace",
      },
    },
    recepit: {
      init: "/receipt",
      specific: {
        transactionId: "?transactionId=",
      },
    },
    requesReport: {
      init: "/core",
      specific: {
        report: "/report",
      },
    },
    presignedUrl: {
      init: "/core",
      specific: {
        preSign: "/presignedUrl",
      },
    },
    fileReport: {
      init: "/dev",
      specific: {
        file: "/files/download",
      },
    },
    alliesAccounts: {
      init: "/allies-accounts",
      specific: {
        getBalanceAccount: "/get-balance-account",
      },
    },
    lowBalance: {
      init: "/low-balance",
      specific: {
        lowBalance: "/low-balance",
      },
    },
    users: {
      init: "/users",
      specific: {
        addresses: "/addresses?id=",
      },
    },
    tokenization: {
      init: "/payments-gateway",
      specific: {
        urls: "/urls-tokenization",
      },
    },
    cards: {
      init: "/payments-gateway",
      specific: {
        cards: "/cards",
      },
    },
    validateReference: {
      init: "/payments-gateway",
      specific: {
        validate: "/card-validations",
      },
    },
    changeModality: {
      init: "/payments-gateway",
      specific: {
        modality: "/modality",
        paymentMethod: "/payment-method-modality",
      },
    },
    statusAccount: {
      init: "/payments-gateway",
      specific: {
        status: "/status-account",
      },
    },
    encryptedRequest: {
      init: "/security-core",
      specific: {
        publicKey: "/auth/publickey",
      },
    },
    transaction: {
      init: "/payments-gateway",
      specific: {
        createTransaction: "/transaction",
        updatePayment: "/payments",
        getTransactions: "/payments/transactions-by-status",
        paymentValidation: "/payment-validations",
      },
    },
  },
  api_key: {
    publicPN: {
      back_url: "https://fap-toll-dev-api-public.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX19F9k9fztI8L8v8yzF8rRhjegV67vI7CmgAytMmZaYgbfZXLpRePII4HF5qzPMvLHvTzwSHvnYRMw==",
    },
    publicPJ: {
      back_url:
        "https://fap-toll-dev-api-public-enterprises.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1/Q051wso/cdp9twCE0+fw5WjOWJb0ZgGbH4BWzfHmBWZPthTHNstPGxTiXM4Y5XjNZI9YYpXFngw==",
    },
    three: {
      back_url: "https://fap-commons-dev-api.avaldigitallabs.com",
    },
    securityPN: {
      back_url: "https://fap-toll-dev-api-security.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX18GMCEPSre4kaWlCkII4KqlZh1peVKypc51TQXvNfPbxHA2DK24cB64OS8jJqzyftwSqYbM6gGLVw==",
    },
    vinculation_process: {
      back_url:
        "https://fap-toll-dev-api-vinculation-process.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1/Z5Ctf27vo7NCIXB9kKDlq38v59PMMRIAzgGmtBd52s4FHZMWsWQmZgH1cauFWisrqBksosKNGqQ==",
    },
    vehicles: {
      back_url: "https://fap-toll-dev-api-vehicles.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX19AWVsYURRplhbtB5CCe3u8aM1HXsid4fZNlA42CKJiTwXr3S4r0WHhxGMneCckaWX9uYkDuu20xA==",
    },
    commons: {
      back_url: "https://fap-commons-dev-api-web-tags.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1+mUvR2aWCz1FSd9vUpDkJQcYmaVvHmEGUaEMABDiJWVrgVVXjeAHzXI52zTC9A5bM7TGICiT6Pfg==",
    },
    securityPJ: {
      back_url:
        "https://fap-toll-dev-api-security-enterprises.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX18GMCEPSre4kaWlCkII4KqlZh1peVKypc51TQXvNfPbxHA2DK24cB64OS8jJqzyftwSqYbM6gGLVw==",
    },
    shared: {
      back_url:
        "https://fap-commons-dev-api-shared-services.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX18Tzdk9jnHzzvOgUIIGgwfk5LWdV8sNXj/jYtWW88hJBPuBMval7nm7RgFctFYVQbBj13KqAhjfbw==",
    },
    payments: {
      back_url: "https://fap-toll-dev-api-payments.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1+UWzodMgwWgyobNXJ7/T06nauuOUWg5s0NgE1KatL9XxQ8p4ScD5iXn+vusJ6+JQji3VAQc5YVEw==",
    },
    report_request: {
      back_url: "https://fap-toll-dev-api-public.avaldigitallabs.com",
    },
    file_report: {
      back_url: "https://22v8f6xn6a.execute-api.us-east-2.amazonaws.com",
      x_api_key: "TFNv71fqL56o104YQdPkB65NtF9rd9Cl8llvmkCD",
    },
    private: {
      back_url: "https://fap-toll-dev-api-priv.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1/GjjWxNP9c3P2PhwquLTvlSuGkoPiz01Q17+ca+urNFEHQVVQmdAiXvyhtxSCVuH+EgVmVqxAKUA==",
    },
    services: {
      back_url: "https://toll-services-facilpass-api-dev.avaldigitallabs.com",
      x_api_key:
        "U2FsdGVkX1/1wlaXudRMUtXx8gCyVW8AVGKeoZgwOAxyKhIj+lUmsXuwU8y/UQUfoO5IAwsz43Yj/oSs1zRb1A==",
    },
    transerversal_services: {
      back_url:
        "https://transversal-services-facilpass-api-dev.avaldigitallabs.com",
    },
  },
  invoicesKey: "BetaZigmaFCL2!x",
  facilpasskey: "F1p1c7lp1ssK3y",
  facilpasskeyPublic: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAj+qTIt2Do5erh+8Uw1RyFgMp18Q8iQbcgcMqmomHmYhRTnWXAATIfFgJ8xXitZnZ+oWIo1MrzM8QllwY8i8lTHDbepTvFLNsm72wv8lEqicRO+wAGZdbTZhXGg7wVN2Cd56pjpug8wuboRAkH6FPFtOuQLs8GumyREE/qiOzXd0Afw6/P/JGX5/kShWl+9FGlyfunR+rmu9bu33tZkNxCa5h4ZwZ0CQp8x6smr5b58RzDmeo6jeJQ6aiTbVbJsoBpM37OEfxX9WxCYabbC33llAam8xy8YmfWt25mZIcQxC9HrfvcGr3rJ3w47w/fo4EeTKbWzUZ0HxlRqUAaxj+9wIDAQAB`,
  tealium: {
    account: "adl",
    profile: "facilpass",
    environment: "dev",
  },
  pse: {
    minimumAmount: "30000",
    hold: "2",
  },
  inactivityTime: 600000,
  keyngCache: "6LeCeTkaAAAAAB68AWaQWH-F_9GPbpe4M3KxB0iX",
  tagInstalationManual:
    "https://fap-toll-dev-documents.s3.us-east-2.amazonaws.com/pdf/ManualDispositivoElectronicoTAG_PJ.pdf",
  documents: {
    url: "https://d2vyylbsadz2q.cloudfront.net/",
    init: "/pdf",
    specific: {
      folder: "/xlsx",
      roles: "/Perfil_Usuario.pdf",
      newVehicleTemplateGuide: "/guia_inscripcion_vehiculos.pdf",
      newVehicleTemplate: "/formato_inscripcion_vehiculos.xlsx",
    },
  },
  reportsFileType: {
    excel: true,
    pdf: false,
    text: false,
  },
  reportType: [
    { name: "movement", enabled: true, reportType: 4 },
    { name: "toll", enabled: false, reportType: 5 },
  ],
  reportLapse: "3",
  avalPayLink:
    "https://www.avalpaycenter.com/wps/portal/portal-de-pagos/web/pagos-aval/resultado-busqueda/realizar-pago?idConv=00004200&origen=buscar",
  termporalCaptcha:
    "03AFcWeA4dYM6CF59ec6T8KzlovB367Mod8_Io8590p_37AODC3pskXzFGHKAyYFVlqA_ESi_7hFK2sxrU_sZl7LCtLzy1rX5M-dnJrVc5sVfl6sFva24XqMLECNW7j5emkld4o6CvyQxAcfMxyosuizI240VMuGayvYFWpKaK7u0SY8RhrmA5-ZlnrsCn-zEHbHEPgnLwt9SXpiGquS12H9gxKx6vfzuLSs95ZKdZ2g0UWrLSPQUK86P2EfkVal4jgOa--v_86Y9ax8wE3HDP7K-c0dFA078F6IyPtd89d_bnkG0w_F53R7KwxyK2Ph91h4tJbcNXm57ZC-OOXsBHaT3ICwdGVw-J_STZfp7ejmCYgHxJdkKZo932TaFPj2Bwpbumecdq7ZzGhgR-Q_yjAa1AJvuMCGa2t7GPwKF6gaSItHXdE5jj08NREkFWMAGlPolacjl1g7q8dwZHzueGd8uHGI2rpsSSxOW3bRpRshwSgDvwQm_rddxf1H_XpA4p-wzb_tbUklmMbrwSZhQYgL7XW7hF_sgQb3MQqFbvJNZCAoYAFhJxf6HancVjdYzLdJ7_hovFm0ceAU-PpXAsyBxgp3GH41znYIbJDwNGNL8ifLjVBypy9_LIUnWydW-GQDBz_YH6G2o4TyUp5tkfMtbB9xjhVeupbv88QEDbvaE32AfE6pVFxM__6S5LOG_LvlqdY-FhmZCc9AEamQ88mlkkVvG780Ah3LgogtfDpTUveEZhpOzVjXtAzgJJGu4k6E9gevuO3LUDC9aB8l1EcdApkr0HKH96EJl11F8Ry0DLSeFF0NU5cxyNGGmrrsLARXpJMS9Dxn1KcOi1JU5JwD_CDE-d8edHo4hdeWfThKa7XePWtw8rsfKhlRLXWm1Gzb4g9n-tIKMKVcdxJftQROTyvK1m1eQStKvQj0h9cyO_O0gHhcZHDbYOeirkGPmegUMwWGUM1Vs4g0cbs-cfiHRhq6xg3o7t6Ezbje2cTpS6WSx8ers8y2E7E8kAgA7aoloEbXGrILAhlMgh5CNWzGjv_-yZJOR629BLkY1lnkObYjVaMYmJ6qox4vEh6A5AnZs_CMkljUcNKXEjmZcIgsbMv2xRylSuBSuK0DFZWxXtS4TCs1QEKIWjt2wjWEajpN7EX9vJSv03lhu90e7e16WLW2fYSt21bs2i20GpFsA-lqaRFZclWglFclVlBs620Eo0nWZ98Cb4WyF0hJboIJ1d1kjlKpAWIzd5tt_DSevO55Knn3ETo91NKNBJJ7D4K82S0BXULaWl2VyDzUZMMCejP954NiXW-jztguklM7O7CopDG6Qi2LTr19mgfyg4b9ZGHCPz4GWsQPLgXtW4AKQI_26oZNdB0gYXZCKdkfIpLCLhte5VSlLPR-LNj4IBwL_LQtLjPS799VSbVCUDHnOlh1a7K1N5fsa8LnGBIbm9Rog9Uvj6ZsDP4YYLiJa0afpXsrea6fM1zvET3X10PGwpttk6DYTNSMDs20ZoeyUvJ2tyIgI5g6bGf5lBTu1BDr4oaDeOTUXic9TlnLEiFDDoOe2W68fDAAXMushF4CxPdmvpkVnDqvlYhPWTPipZiTE1b0dVae4X5jOzIfnckTe-B2jAxWWheQnoKt0sSzolCCcXkmOR0GEEi7R7Vru321zcJq_BbwLsZPyljDFEme6JQ49W7kS9oYLQ7_ID82qjenBazCmAYVFFkrimZ5tM3abdrc-btJXo9VfybtZJNdO7cbv2TEd5cyVPAuOsMbbc9V-_rKSUxLjaGaAEPsro5Uuff4L3_5Xn1GoS6UlvIiJMi8pcC0kljw2hwqb4ewi8HqnwRERiolFt66sFe1JmxLJI0nmkhFRprxh-CpO7bVMbEsuSBNVWAsaqxX6U4-IsVXtytHorGYiY7Ev5lfRKsUF80x9vbD25Y8ei2UGgC2-bZYwODLb8fQ",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
