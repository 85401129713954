import { ICompany } from "@app/models/all-data";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { companyDataFeatureName } from "../state/company-data.state";

export const CompanyDataRootSelector = createFeatureSelector<ICompany>(
  companyDataFeatureName,
);

export const selectCompanyData = createSelector(
  CompanyDataRootSelector,
  (state: ICompany) => state,
);
