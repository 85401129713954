<div class="container-card-header" *ngIf="headerType">
    <div class="card-header" [ngSwitch]="headerType">
        <ng-container *ngSwitchCase="'primary'">
            <img class="card-header-logo" src="assets/images/logo/logo.svg" alt="logo facilpass">
            <div class="header-stepper primary" *ngIf="infoStepper">
                <div class="stepper-content">
                    <div class="stepper-proggres-background">
                        <div class="stepper-proggres-active" [style.width]="infoStepper.percent+'%'">
                        </div>
                    </div>
                    <div class="container-step" [class.active]="step.state ==='active' || step.state ==='successful'"
                        *ngFor="let step of infoStepper.steps">
                        <div class="step" [class.successful]="step.state ==='successful'"
                            [class.active]="step.state ==='active'">{{ step.state ==='active'? step.step
                            :'' }}</div>
                        <div class="step-label">{{step.description}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'secondary'">
            <div class="header-type-tow" [class.header-dashboard]="template === 'dashboardTemplate' || template === 'fourTemplate'">
                <div class="d-flex" [style.visibility]="backOption? 'visible':'hidden'"
                    (click)="backOption?back(): null">
                    <img src="assets/images/close/icon-back.svg" alt="cerrar vista">
                    <span class="span-exit">Volver</span>
                </div>
                <div *ngIf="navigateBackOtion" class="d-flex navigateBack" [style.visibility]="navigateBackOtion? 'visible':'hidden'"
                    (click)="navigateBackOtion?navegateBack(): null">
                    <img src="assets/images/close/icon-back.svg" alt="cerrar vista">
                    <span class="span-exit">Volver</span>
                </div>
                <div class="d-flex" *ngIf="close" (click)="showPopUp?openModal('fp-exit-progress'):rigthAction()">
                    <span>Cerrar</span>
                    <img src="assets/images/close/close-vista.svg" alt="cerrar vista">
                </div>
                <div class="d-flex" *ngIf="exit" (click)="rigthAction()">
                    <span class="span-exit">Salida segura</span>
                    <img src="assets/images/common/icon-interface-logout.svg" alt="secure signout">
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'tertiary'">
            <div class="header-type-third">
                <div class="d-flex mr-auto" [style.visibility]="backOption? 'visible':'hidden'"
                    (click)="backOption?back(): null">
                    <img src="assets/images/close/icon-back.svg" alt="cerrar vista">
                    <span class="span-exit">Volver</span>
                </div>
                <div class="header-stepper" *ngIf="infoStepper">
                    <div class="stepper-content">
                        <div class="stepper-proggres-background">
                            <div class="stepper-proggres-active" [style.width]="infoStepper.percent+'%'">
                            </div>
                        </div>
                        <div class="container-step"
                            [class.active]="step.state ==='active' || step.state ==='successful'"
                            *ngFor="let step of infoStepper.steps">
                            <div class="step" [class.successful]="step.state ==='successful'"
                                [class.active]="step.state ==='active'">{{ step.state ==='active'? step.step
                                :'' }}</div>
                            <div class="step-label">{{step.description}}</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex ml-auto" (click)="showPopUp?openModal('fp-exit-progress'):rigthAction()">
                    <span>Cerrar</span>
                    <img src="assets/images/close/close-vista.svg" alt="cerrar vista">
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'quaternary'">
            <img class="logo-facilpass-third" src="assets/images/logo/logo.svg" alt="">
        </ng-container>
    </div>
</div>
<div class="container-body-templates">
    <div class="container-register" *ngIf="template === 'dashboardTemplate' || template === 'fourTemplate'">
        <fp-nav-menu></fp-nav-menu>
    </div>
    <div class="container-register" [class.dashboard]="template === 'dashboardTemplate'">
        <div class="content-balls-header" [class.third]="template === 'thirdTemplate'"
            [class.four]="template === 'fourTemplate'" *ngIf="template !== 'dashboardTemplate'">
            <div class="circle-one-header"></div>
            <div class="circle-two-header"></div>
        </div>
        <ng-container *ngIf="template === 'firstTemplate'">
            <h1 class="fp-h1 card-title">{{title}}</h1>
            <h1 class="fp-body-one card-subtitle" [innerHtml]="subTitleSanitizer"></h1>
            <div class="container-mask">
                <div class="container-form">
                    <div class="form-card-body">
                        <div class="content-form-card-body">
                            <ng-template *ngTemplateOutlet="tplRef"></ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="template === 'secondTemplate'">
            <div class="container-second">
                <ng-template *ngTemplateOutlet="tplRef"></ng-template>
            </div>
        </ng-container>
        <ng-container *ngIf="template === 'thirdTemplate'">
            <img class="logo-facilpass-third" src="assets/images/logo/logo.svg" alt="">
            <div class="container-mask">
                <div class="container-form">
                    <div class="form-card-body">
                        <div class="content-form-card-body">
                            <ng-template *ngTemplateOutlet="tplRef"></ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="content-balls-body" *ngIf="showfooterBallsRight">
            <div class="circle-one-body"></div>
            <div class="circle-two-body"></div>
        </div>
        <ng-container *ngIf="template === 'dashboardTemplate' || template === 'fourTemplate'">
            <div class="container-second" *ngIf="oneColumn">
                <ng-template *ngTemplateOutlet="tplRef"></ng-template>
            </div>
            <div *ngIf="!oneColumn" class="container-content-divider">
                <div class="seccion one">
                    <div class="content-one">
                        <div class="container-second dashboard">
                            <ng-template *ngTemplateOutlet="tplRef"></ng-template>
                        </div>
                    </div>
                </div>
                <div class="seccion two">
                    <div class="content-two">
                        <div class="header-seccion">
                            <div class="content-balls-header dashboard">
                                <div class="circle-one-header"></div>
                                <div class="circle-two-header"></div>
                            </div>
                        </div>
                        <div class="container-second dashboard mt-small">
                            <ng-template *ngTemplateOutlet="tplRefTwo"></ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="circle-arc" [class.dashboard]="template === 'dashboardTemplate' || oneColumn"></div>
<fp-modal [typeModal]="'showConfirm'" id="fp-exit-progress" iconModalConfimr="assets/images/home/icon-war.svg"
    [textConfirm]="subtitleModalSecureExit" [titleConfirm]="'¿Esta seguro?'" [typeModalBig]="true"
    [btnAccept]="'Continuar'" [btnExit]="'Salir del proceso'" [hideClose]="true" (actionModal)="actionModal($event)">
</fp-modal>