import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import Joi from "joi";

export function joiValidator(schema: Joi.Schema): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { error } = schema.validate(control.value);
    return error ? { joi: error.details[0].message } : null;
  };
}

const allowedTlds = [
  "com",
  "net",
  "org",
  "edu",
  "gov",
  "mil",
  "int",
  "co",
  "us",
  "uk",
  "de",
  "jp",
  "fr",
  "au",
  "ru",
  "ch",
  "it",
  "nl",
  "se",
  "no",
  "es",
  "ca",
  "br",
  "in",
  "cn",
  "za",
  "mx",
  "ar",
  "cl",
  "nz",
  "kr",
  "sg",
  "my",
  "id",
  "th",
  "vn",
  "ph",
  "hk",
  "tw",
  "biz",
];
export const emailSchema = Joi.string()
  .email({ tlds: { allow: allowedTlds } })
  .max(255)
  .required();
