import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { IAllData } from "@app/models/all-data";
import { environment } from "@environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataPolicyService {
  constructor(private http: HttpClient) {}

  getOtpValidation(data: any): Observable<any> {
    return this.http.post(
      environment.api_key.services.back_url +
        environment.endpoints.core.init +
        environment.endpoints.core.specific.customerOtp,
      data,
      {
        params: new CustomHttpParams("api-toll-services"),
      },
    );
  }

  validateOtpCode(data: any): Observable<any> {
    return this.http.post(
      environment.api_key.services.back_url +
        environment.endpoints.core.init +
        environment.endpoints.core.specific.validateOtp,
      data,
      {
        params: new CustomHttpParams("api-toll-services"),
      },
    );
  }

  updateDataPolicy(request: IAllData): Observable<any> {
    return this.http.post<IAllData>(
      environment.api_key.services.back_url +
        environment.endpoints.core.init +
        environment.endpoints.core.specific.customer +
        environment.endpoints.core.specific.update,
      request,
      {
        params: new CustomHttpParams("api-token"),
      },
    );
  }
}
