import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeCOP from "@angular/common/locales/es-CO";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  InjectionToken,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtModule } from "@auth0/angular-jwt";
import { CoreModule } from "@core/core.module";
import { EffectsModule } from "@ngrx/effects";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AccountsEffect } from "@store/effects/accounts.effects";
import { CitiesEffect } from "@store/effects/cities.effects";
import { CustomerInfoEffect } from "@store/effects/customer-info.effects";
import { IdentificationTypesEffect } from "@store/effects/identification-types.effect";
import { RolesEffect } from "@store/effects/roles.effect";
import { UserInfoEffect } from "@store/effects/user-info.effects";
import { metaReducers } from "@store/hydration/index.reducer";
import { AppRootReducer, IAppState } from "@store/reducers/index.app.reducer";
import { ThemeModule } from "@theme/theme.module";
import { environment } from "environments/environment";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { NgxPermissionsGuard, NgxPermissionsModule } from "ngx-permissions";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppFacade } from "./app.facade";
import { windowProvider, WindowToken } from "./helpers/windows.helpers";
import { NGFORAGE_CONFIG_PROVIDER } from "./ngforage.config";
import { AddressEffect } from "./store/effects/address.effects";
import { AllDataEffect } from "./store/effects/all-data.effects";
import { LowBalanceEffect } from "./store/effects/low-balance.effects";

registerLocaleData(localeCOP);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function tokenGetter() {
  return null;
}

export const AppRootReducerToken = new InjectionToken<
  ActionReducerMap<IAppState>
>("Feature App Component");

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(AppRootReducerToken, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      IdentificationTypesEffect,
      CitiesEffect,
      UserInfoEffect,
      CustomerInfoEffect,
      AccountsEffect,
      RolesEffect,
      LowBalanceEffect,
      AddressEffect,
      AllDataEffect,
    ]),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 50,
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "es",
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
      isolate: true,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    ReactiveFormsModule,
    ThemeModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-co" },
    {
      provide: AppRootReducerToken,
      useValue: AppRootReducer,
    },
    NGFORAGE_CONFIG_PROVIDER,
    AppFacade,
    { provide: WindowToken, useValue: windowProvider },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.keyngCache,
    },
    NgxPermissionsGuard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
