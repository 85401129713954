import { ICompany } from "@app/models/all-data";

export const companyDataFeatureName = "companyDataReducer";

export const initialConpanyDataState: ICompany = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
