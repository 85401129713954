import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IAccounts } from "@app/models/all-data";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-successful-process",
  templateUrl: "./successful-process.component.html",
  styleUrls: ["./successful-process.component.scss"],
})
export class SuccessfulProcessComponent implements OnInit, OnDestroy {
  /** Destroy observables  */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Account data */
  account: IAccounts;
  /** Account id masked */
  accountIdMasked: string = "";

  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private router: Router,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  ngOnInit(): void {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccounts) => {
        this.account = { ...action };
        this.accountIdMasked = this.account?.accountId?.toString().substring(1);
      });
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * Redirtect to page
   */
  redirectToPage(): void {
    this.router.navigate(["/home"]);
  }

  /**
   * Select language
   * @readonly
   * @type {Observable<Languages>}
   * @memberof SuccessfulProcessComponent
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Select account selected
   * @readonly
   * @type {Observable<IAccounts>}
   * @memberof SuccessfulProcessComponent
   */
  get selectAccountSelected$(): Observable<IAccounts> {
    return this.appFacade.selectAccountSelected$;
  }
}
