<ng-container *ngIf="!loading">
    <div class="container-card-documents" *ngIf="showCarousel">
        <div class="header-card-documents">
            <div>
                <p class="detail-steps">Continúe con el proceso</p>
                <h6 class="title-steps">{{stepSuccessful}} de {{infoDocumentStep}} completados</h6>
            </div>
            <div *ngIf="userData?.currentStatus === '18022'">
                <div class="document-warning">
                    <img src="assets/images/home/icon-war.svg" alt="icono advertencia">
                    <span>En validación de documentos</span>
                </div>
            </div>
        </div>
        <div class="container-documents-carousel">
            <button class="fp-btn-white btn-control-carousel" id="dashboard-carrousel-account-prev"
                (click)="infoDocumentCarousel.prev();refundActive? null:evaluateCarouselIndex(-1)"
                *ngIf="!refundActive">
                <img src="assets/images/home/arrow-carousel.svg" alt="control carousel">
            </button>
            <owl-carousel-o #infoDocumentCarousel [options]="customOptions">
                <ng-container *ngFor="let infoDocument of infoDocumentCopy; let i = index">
                    <ng-template carouselSlide [id]="i">
                        <fp-item-document [infoDocument]="infoDocument" (clickItem)="callToAcctionCarousel($event)">
                        </fp-item-document>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
            <button class="fp-btn-white btn-control-carousel right" id="dashboard-carrousel-account-prev"
                (click)="infoDocumentCarousel.next();refundActive? null:evaluateCarouselIndex(1)" *ngIf="!refundActive">
                <img class="rotar" src="assets/images/home/arrow-carousel.svg" alt="control carousel">
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="loading">
    <div class="container-card-documents fake">
        <div class="header-card-documents">
            <div style="width: 100%;">
                <p class="detail-steps skeleton"></p>
                <h6 class="title-steps skeleton"></h6>
            </div>
            <div style="width: 100%;">
                <div class="document-warning skeleton">
                </div>
                <p class="text-fake"></p>
            </div>
        </div>
        <div class="container-documents-carousel fake">
            <button class="fp-btn-white btn-control-carousel skeleton">
            </button>
            <div class="card-item-document-fake" *ngFor="let item of [1,2]">
                <div class="container-item">
                    <div class="item-mask-icon skeleton">
                    </div>
                    <p class="item-text-description skeleton"></p>
                </div>
            </div>
            <button class="fp-btn-white btn-control-carousel right skeleton">
            </button>
        </div>
    </div>
</ng-container>
<div class="owl-stage" style="display: none;"></div>
<fp-pop-up-documents-upload></fp-pop-up-documents-upload>
<fp-not-allowed-action></fp-not-allowed-action>
<fp-new-vehicle></fp-new-vehicle>