import { ILowBalance, IAccountLowBalance } from "@app/models/accounts";
import { IAccount, IAccounts } from "@app/models/all-data";
import { IDocumentTypeService } from "@app/models/form-validation-messages";
import { IRolesAdministrator } from "@app/models/roles-administrator";
import { ILanguage } from "../reducers/app.reducer";

export const AppFeatureName = "language";

export const initialState: ILanguage = {
  language: "es",
};
export const IdentificationTypesFetureName = "identificationTypes";

export const accountsFeatureName = "accountsReducer";
export const accountSelectedFeatureName = "accountSelectedReducer";
export const rolesAdministratorFeatureName = "rolesAdministratorReducer";
export const lowBalanceFeatureName = "lowBalanceReducer";
export const lowBalanceSelectedFeatureName = "lowBalanceSelectedReducer";

export interface IIdentifiTypesState {
  documenTypes: IDocumentTypeService;
  error: any;
  loaded: boolean;
  loading: boolean;
}

export const initialAccountState: IAccount = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

export const initialAccountSelectedState: IAccounts = {
  version: null,
  accountId: null,
  maxCategoryVehicle: null,
  createdAt: null,
  createdBy: null,
  documents: null,
  contractNumber: null,
  balance: null,
  stateBalance: null,
  balanceMin: null,
  nure: null,
  balanceLow: null,
  statusAccount: null,
  personId: null,
  statusAccountCod: null,
  stateLowBalance: null,
};

export const initialStateRolesAdministrator: IRolesAdministrator = {
  roles: "",
};

export const initialLowBalanceState: ILowBalance = {
  data: null,
};

export const initialLowBalanceSelectedState: IAccountLowBalance = {
  accountId: null,
  balance: null,
  financialProduct: null,
  minimumValueRecharge: null,
  lowBalanceRecharge: null,
  emailNotification: null,
  accountType: null,
};
