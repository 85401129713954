import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { IAccount, IAccounts } from "@app/models/all-data";
import { AuthenticationService } from "@app/modules/shared/services/authentication.service";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-account-info",
  templateUrl: "./account-info.component.html",
  styleUrls: ["./account-info.component.scss"],
})
export class AccountInfoComponent implements OnDestroy, OnInit {
  /** Account data */
  accountData: IAccounts;
  /** Destroy observables */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Loading */
  loading: boolean = true;
  /** Emit account data */
  @Output() accountDataEmit = new EventEmitter<IAccounts>();

  @Input() accountDataFromParent: IAccounts | null = null;

  /**
   * Constructor
   * @param appFacade
   * @param translate
   */
  constructor(
    private appFacade: AppFacade,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * Init component
   */
  ngOnInit(): void {
    this.accountDataEmit.emit(this.accountDataFromParent);
    if (!this.accountDataFromParent) {
      this.getAccountData();
    }
    this.accountData = this.accountDataFromParent;
  }

  /**
   * Get account data
   */
  async getAccountData(): Promise<any> {
    this.appFacade.getAccounts(await this.authenticationService.getUsername());
    this.selectAccounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccount) => {
        this.loading = action.loading;
        if (action && action.data !== null && action.data?.length > 0) {
          this.accountData = action.data[0];
          this.accountDataEmit.emit(this.accountData);
        }
      });
  }

  /**
   * Get select language
   * @readonly
   * @type {Observable<Languages>}
   * @memberof AccountInfoComponent
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Get select accounts
   * @readonly
   * @type {Observable<IAccount>}
   * @memberof AccountInfoComponent
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }
}
