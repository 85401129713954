<fp-card-body [showBackButton]="'hidden'" [template]="'thirdTemplate'">
    <ng-template #tplRef>
        <form novalidate [formGroup]="formRegister" (ngSubmit)="registerUser()">
            <div class="title_box">
                <h1 class="fp-h1 register-title">
                    {{ 'register_title' | translate }}
                </h1>
                <span class="fp-body-one register-subtitle">
                    {{ 'register_subtitle' | translate }}
                </span>
            </div>

            <div class="container-input-section">
                <div class="input-large-section">
                    <fp-input #inputNit (onBlur)="validateChangeNit()" (onInput)="validationVerificationDigit()"
                        class="form-group" labelClass="form-label" styleClass="form-group"
                        nameLabelTranslate="label_nit" viewSpaceInput="true" [viewErrorsInput]="true"
                        [viewIconErrorInput]="true" [inputClass]="'input-large'" typeInput="tel" nameInput="nit"
                        idInput="nit" maxlength="15" formControlName="nit"
                        [inputValidationMessages]="registerValidationMessages.nit"
                        [formControl]="formRegister.controls['nit']" regexp="^[0-9]{0,10}$" matchRegexp="(\d+)"
                        [viewMessageError]="focusDiv">
                    </fp-input>
                </div>
                <div class="input-small-section">
                    <fp-input #inputDv (onBlur)="validateChangeNit()" (onInput)="validationVerificationDigit()"
                        class="form-group" labelClass="form-label" styleClass="form-group" nameLabelTranslate="dv"
                        viewSpaceInput="true" [viewIconErrorInput]="true" [inputClass]="'input-large'"
                        typeInput="tel" nameInput="dv" idInput="dv" maxlength="1" formControlName="dv"
                        [formControl]="formRegister.controls['dv']" regexp="^[0-9]{0,10}$" matchRegexp="(\d+)"
                        [viewMessageError]="focusDiv">
                    </fp-input>
                </div>
            </div>
            <div class="form-group">
                <div class="space-inputs-title"> </div>
                <label class="form-label">{{ 'register_id_type' | translate }}</label>

                <div class="form-input-group form-input-type-document" id="typeDocuments">
                    <ng-container *ngFor="let typeDocument of typeDocuments; let itype= index">
                        <div class="conten-input-document">
                            <button class="input-small"
                                [class.selected-option]="this.formRegister.controls.identificationType.value === typeDocument.value"
                                type="button" (click)="chooseDocumentType(typeDocument.value)"
                                id="type{{typeDocument.value}}">
                                {{ typeDocument.description }}
                                <span class="radio-btn-container">
                                    <fp-radiobutton formControlName="identificationType" name="identificationType"
                                        [value]="typeDocument.value" inputId="identificationType{{typeDocument.value}}">
                                    </fp-radiobutton>
                                </span>
                            </button>
                        </div>
                    </ng-container>
                </div>
                <div class="space-inputs-small"></div>
            </div>
            <fp-input #idnumber class="form-group" labelClass="form-label" nameLabelTranslate="register_id_number"
                viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
                [inputClass]="formRegister.controls['identificationNumber'].valid ? 'black-border': 'input-large'"
                typeInput="tel" nameInput="identificationNumber" idInput="identificationNumber" maxlength="10"
                formControlName="identificationNumber"
                [inputValidationMessages]="registerValidationMessages.identificationNumber"
                [formControl]="formRegister.controls['identificationNumber']" regexp="^[0-9]{0,10}$"
                matchRegexp="(\d+)"></fp-input>

            <fp-input class="form-group" labelClass="form-label" nameLabelTranslate="register_name"
                viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
                [inputClass]="formRegister.controls['name'].valid ? 'black-border': 'input-large'" typeInput="text"
                nameInput="names" idInput="names" maxlength="50" formControlName="name"
                [inputValidationMessages]="registerValidationMessages.name"
                [formControl]="formRegister.controls['name']" regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$"
                matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ ])" [matchOnInput]="false"></fp-input>


            <fp-input class="form-group" labelClass="form-label" nameLabelTranslate="register_last_name"
                viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
                [inputClass]="formRegister.controls['lastname'].valid ? 'black-border': 'input-large'" typeInput="text"
                nameInput="lastname" idInput="lastname" maxlength="50" formControlName="lastname"
                [inputValidationMessages]="registerValidationMessages.lastname"
                [formControl]="formRegister.controls['lastname']" regexp="^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$"
                matchRegexp="([A-Za-zÁÉÍÓÚáéíóúñÑ ])" [matchOnInput]="false"></fp-input>


            <fp-input #inputRegister class="form-group" styleClass="form-group" labelClass="form-label"
                nameLabelTranslate="register_phone" viewSpaceInput="true" [viewErrorsInput]="true"
                [viewIconErrorInput]="true"
                [inputClass]="formRegister.controls['phone'].valid ? 'black-border': 'input-large'" typeInput="tel"
                nameInput="phone" idInput="phone" maxlength="10" formControlName="phone"
                [inputValidationMessages]="registerValidationMessages.phone"
                [formControl]="formRegister.controls['phone']" regexp="[0123456789]" matchRegexp="(\d+)"
                [viewMessageError]="viewMessageError" (onInput)="inputEvent($event, inputRegister)"></fp-input>


            <fp-input #inputEmail class="form-group" labelClass="form-label" nameLabelTranslate="register_email"
                viewSpaceInput="true" [viewErrorsInput]="true" [viewIconErrorInput]="true"
                [inputClass]="formRegister.controls['email'].valid ? 'black-border': 'input-large'" typeInput="email"
                nameInput="email" idInput="email" formControlName="email"
                [inputValidationMessages]="registerValidationMessages.email"
                [formControl]="formRegister.controls['email']" regexp="^[A-Za-z0-9-+@_\-\.]+$"
                matchRegexp="([a-zA-Z0-9-+_]+[@.\-]*)">
            </fp-input>


            <div class="form-group">
                <div>
                    <div class="row-terms">
                        <fp-checkbox
                            chkboxId="chkboxpersonaldata" name="checkpersonaldata" inputId="checkpersonaldata"
                            [formControl]="formRegister.controls['personalData']" binary="true">
                        </fp-checkbox>
                        <a class="checkbox-obligatori check-habeas-data">
                            {{ "read_accept.policy" | translate }}
                            <u class="fp-btn-link" id="link-habeas-data" (click)="openUrl()">{{
                                "i_accept.policy_authorize" | translate }}</u>
                        </a>
                    </div>
                    <div class="separador">
                    </div>
                </div>
                <!--<re-captcha siteKey="6LfuMa0qAAAAACWosWGKQc9OuDw0qP83dC_Dxe_E"></re-captcha>-->
                <!--<div class="recaptcha-container">
                    <div class="container-items">
                        <label class="recaptcha-label">
                            <fp-checkbox
                                chkboxId="chkboxcaptcha" name="chkboxcaptcha" inputId="chkboxcaptcha" [className]="classNameCheckBox"
                                [formControl]="formRegister.controls['token']" binary="true">
                            </fp-checkbox>
                              <span class="text">I'm not a robot</span>
                            </label>
                    </div>
                        
                    <div class="recaptcha-logo">
                      <img src="https://www.gstatic.com/recaptcha/api2/logo_48.png" alt="reCAPTCHA logo">
                      <div class="rc-anchor-logo-text">reCAPTCHA</div>
                      <div class="recaptcha-privacy">
                        <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">Privacy</a>
                        -
                        <a href="https://www.google.com/intl/en/policies/terms/" target="_blank">Terms</a>
                      </div>
                    </div>
                </div>-->
                <div class="separador">
                </div>
                <div class="separador">
                </div>

                
            </div>

            <div class="form-group-submit">
                <div class="fp-container-btn">
                    <button class="fp-btn-primary submit-btn" type="submit" id="buttonContinue"
                        [disabled]="!this.formRegister.valid || this.sending">
                        <span class="btn-content-text">
                            {{ 'register_btn_continue' | translate }}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </ng-template>

</fp-card-body>

<ng-template #errorDataPolicy>
    <fp-snack-message class="fp-snack-message-login" (snackMessageEvent)="closeMessage()"
        [description]="'Se presento un error, inténtelo de nuevo.'" type="error">
    </fp-snack-message>
</ng-template>