import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { pageEvent, pageViewEvent } from "@app/helpers/utag.helpers";
import { IAccount, IAccounts } from "@app/models/all-data";
import { AuthenticationService } from "@app/modules/shared/services/authentication.service";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { NotifyService } from "@shared/services/notify.service";
import { ModalService } from "@theme/components/modal/service/modal.service";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IAccountModality } from "../../models/account-modality";
import { AccountModalityTypes } from "../../models/account-modality.enum";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";
import { AccountNavigationService } from "../../services/account-navigation-service.service";
import { AccountRequestsService } from "../../services/account-requests/account-requests.service";
import { AccountFacade } from "../../store/facade/account.facade";

@Component({
  selector: "fp-select-modality",
  templateUrl: "./select-modality.component.html",
  styleUrls: ["./select-modality.component.scss"],
})
export class SelectModalityComponent implements OnInit, OnDestroy {
  /** Notify message */
  @ViewChild("notify", { static: true }) notify: TemplateRef<any>;
  /** Selected modality */
  formSelectModality: UntypedFormGroup;
  /** Destroy observables */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Modalities builder */
  modalities = [
    {
      value: "1",
      recommended: true,
      name: "Prepago",
      code: "PRE",
      description: "new_prepago.info",
      enabled: true,
      info: [
        {
          text: "new_prepago.text1",
        },
        {
          text: "new_prepago.text2",
        },
        {
          text: "new_prepago.text3",
        },
      ],
    },
    {
      value: "2",
      recommended: true,
      name: "Automática",
      code: "PRE-REC",
      description: "automatic.info",
      enabled: JSON.parse(sessionStorage.getItem("publicKey"))?.config
        ?.recurrent,
      info: [
        {
          text: "automatic.text1",
        },
        {
          text: "automatic.text2",
        },
        {
          text: "automatic.text3",
        },
      ],
    },
  ];
  /** title */
  title: string = "general_title";
  /** subtitle */
  subtitle: string = "general_subtitle_modality";
  /** Modal title */
  modalTitle: string = "modal.title_change";
  /** Modal info bubble */
  modalInfoBubble: string = "modal.info_bubble_change";
  /** Account selected */
  accountSelected: IAccounts;
  /** Account type */
  actualAccountType = null;
  /** Account selected type */
  accountSelectedType: string;
  /** Sending data */
  changingModality = false;
  /** Modality selected */
  modalitySelected = undefined;
  /** Account data */
  automaticRechargeData: IAutomaticRecharge;

  /**
   * Constructor
   * @param snackBar
   * @param appFacade
   * @param translate
   * @param modalService
   * @param accountNavigationService
   * @param notifyService
   * @param accountFacade
   * @param accountRequestsService
   */
  constructor(
    private snackBar: MatSnackBar,
    private appFacade: AppFacade,
    private translate: TranslateService,
    private modalService: ModalService,
    private accountNavigationService: AccountNavigationService,
    private notifyService: NotifyService,
    private accountFacade: AccountFacade,
    private accountRequestsService: AccountRequestsService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.closeNotify();
  }

  /**
   * Init components
   */
  async ngOnInit(): Promise<any> {
    this.changingModality = false;
    this.initForm();
    await this.getInitialState();
  }

  /**
   * Get initial state
   */
  async getInitialState(): Promise<any> {
    const promiseAccountModality = await this.selectAccountModality$
      .pipe(take(1))
      .toPromise();
    this.automaticRechargeData = await this.selectAutomaticRecharge$
      .pipe(take(1))
      .toPromise();
    await this.getAccountSelected();
    await this.getAccounts();
    this.sendAccountData();
    if (this.actualAccountType && promiseAccountModality.modalitySelected) {
      this.chooseModality(promiseAccountModality.modalitySelected);
    }
  }

  /**
   * Get account selected
   */
  async getAccountSelected(): Promise<any> {
    this.accountSelected = await this.selectAccountSelected$
      ?.pipe(take(1))
      ?.toPromise();
    this.accountSelectedType = this.accountSelected?.accountType;
    if (!this.accountSelectedType) {
      this.modalTitle = "modal.title_choose";
      this.modalInfoBubble = "modal.info_bubble_choose";
    }
    if (this.accountSelectedType === "PRE") {
      this.chooseModality("1");
    }
    if (this.accountSelectedType === "PRE-REC") {
      this.chooseModality("2");
    }
  }

  /**
   * Get accounts
   */
  async getAccounts(): Promise<any> {
    const promiseAccounts = await this.selectAccounts$
      ?.pipe(take(1))
      ?.toPromise();
    if (promiseAccounts?.data !== null) {
      const account = promiseAccounts?.data?.find(
        (x) => x.accountId === this.accountSelected?.accountId,
      );
      this.actualAccountType = account?.accountType;
    }
  }

  sendAccountData() {
    this.accountRequestsService.changeData(this.accountSelected);
  }

  /**
   * Show notify
   */
  showNotify(): void {
    this.notifyService.showNotify(this.notify, "fp-snack-modify");
    setTimeout(() => {
      this.closeNotify();
    }, 5000);
  }

  /**
   * Close notify
   */
  closeNotify(): void {
    this.snackBar.dismiss();
  }

  /**
   * Init form
   */
  initForm(): void {
    this.formSelectModality = new UntypedFormGroup({
      paymentType: new UntypedFormControl("", [Validators.required]),
    });
  }

  /**
   * Select modality when click continua button
   */
  async selectModality(): Promise<any> {
    // Tagging click event
    pageEvent(
      "click-btn-continue-modality-selected",
      "/account/select-modality",
      "Modality selected continue",
    );
    if (this.accountSelectedType === "") {
      if (this.formSelectModality?.controls?.paymentType?.value === "1") {
        this.modalService.open("fp-modal-payment-confirm-pre");
      } else {
        await this.callService();
      }
    } else {
      const modal =
        this.formSelectModality?.controls?.paymentType?.value === "1"
          ? "fp-modal-payment-confirm-pre"
          : "fp-modal-payment-confirm-aut";
      this.modalTitle =
        this.formSelectModality?.controls?.paymentType?.value === "1"
          ? "modal.title_change"
          : "modal.title_aut_selected";
      this.modalService.open(modal);
    }
  }

  /**
   * Call service
   */
  async callService(): Promise<any> {
    if (!this.changingModality) {
      this.closeModal();
      if (this.formSelectModality?.controls?.paymentType?.value === "1") {
        this.changingModality = true;
        const payload = {
          modality: AccountModalityTypes.PREPAGO,
          accountId: this.accountSelected?.accountId.toString(),
        };
        const request = await this.accountRequestsService.changeModality(
          payload,
        );
        request.pipe(takeUntil(this.destroy$)).subscribe({
          next: () => {
            this.accountFacade.resetSetAutomaticRecharge();

            this.appFacade.getAllData();

            this.router.navigate(["/transactions/recharge"]);
          },
          error: () => {
            this.changingModality = false;
            this.showNotify();
          },
        });
        return;
      }
    }

    if (this.formSelectModality?.controls?.paymentType?.value === "2") {
      // Tagging click event
      pageEvent(
        "click-btn-understood-automatic-modality",
        "/account/select-modality",
        "Automatic modality understood message modal",
      );
      this.accountFacade.resetSetAutomaticRecharge();
      this.accountNavigationService.next(3);
      return;
    }
  }

  /**
   * Set the modality in the form when selecting a card
   * @param method string
   */
  chooseModality(method: string): void {
    if (!this.changingModality) {
      this.formSelectModality?.controls?.paymentType?.setValue(method);
      this.modalitySelected = this.modalities?.find(
        (modality) => modality.value === method,
      )?.code;
      // Tagging click event
      pageEvent(
        "select-modality-" + method === "1" ? "prepaid" : "automatic",
        "/account/select-modality",
        "Modality " + method === "1" ? "prepaid" : "automatic" + "selected",
      );
    }
  }

  /**
   * Open modal
   */
  openModal(): void {
    const modal =
      this.formSelectModality?.value?.paymentType === "1"
        ? "fp-modal-payment-confirm-pre"
        : "fp-modal-payment-confirm-aut";
    this.modalService.open(modal);
    pageViewEvent(`/account/select-modality/${modal}`);
  }

  /**
   * Close modal
   */
  closeModal(): void {
    const modal =
      this.formSelectModality?.value?.paymentType === "1"
        ? "fp-modal-payment-confirm-pre"
        : "fp-modal-payment-confirm-aut";
    this.modalService.close(modal, false);
  }

  /**
   * Get select language
   * @readonly
   * @type {Observable<Languages>}
   * @memberof SelectModalityComponent
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Select account selected
   * @readonly
   * @type {Observable<IAccounts>}
   * @memberof SelectModalityComponent
   */
  get selectAccountSelected$(): Observable<IAccounts> {
    return this.appFacade.selectAccountSelected$;
  }

  /**
   * Select account modality
   * @readonly
   * @type {Observable<IAccountModality>}
   * @memberof SelectModalityComponent
   */
  get selectAccountModality$(): Observable<IAccountModality> {
    return this.accountFacade.selectAccountModality$;
  }

  /**
   * Select accounts
   * @readonly
   * @type {Observable<IAccount>}
   * @memberof SelectModalityComponent
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  /**
   * Select automatic recharge data
   * @readonly
   * @type {Observable<IAutomaticRecharge>}
   */
  get selectAutomaticRecharge$(): Observable<IAutomaticRecharge> {
    return this.accountFacade.selectAutomaticRecharge$;
  }
}
