import { AccountRequestsService } from "@account/services/account-requests/account-requests.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IAccount, IAccounts } from "@app/models/all-data";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IAutomaticRecharge } from "../../models/automatic-recharge.interface";
import { AccountFacade } from "../../store/facade/account.facade";

@Component({
  selector: "fp-success-change-modality",
  templateUrl: "./success-change-modality.component.html",
  styleUrls: ["./success-change-modality.component.scss"],
})
export class SuccessChangeModalityComponent implements OnInit, OnDestroy {
  /** Destroy observables */
  destroy$: Subject<boolean> = new Subject<boolean>();
  /** Account data */
  accountData: IAccounts;
  /** Automatic recharge data */
  automaticRechargeData: IAutomaticRecharge;
  /** Loading */
  loading: boolean = true;
  /** Today */
  today: Date = new Date();
  /** Brands cards */
  cardsBrands = {
    visa: "assets/images/common/visa-logo.svg",
    master: "assets/images/common/master-card-logo.svg",
  };
  /** Updating data flag */
  updating = false;

  constructor(
    private appFacade: AppFacade,
    private translate: TranslateService,
    private accountFacade: AccountFacade,
    private accountRequestsService: AccountRequestsService,
    private router: Router,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.selectAccounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccount) => {
        this.loading = action.loading;
        if (action && action.data !== null && action.data?.length > 0) {
          this.accountData = action.data[0];
          this.updating = this.accountData?.financialProduct
            ? Object.keys(this.accountData?.financialProduct).length > 0
            : false;
        }
      });
    this.getAccount();
    this.automaticRechargeData = await this.selectAutomaticRecharge$
      .pipe(take(1))
      .toPromise();
  }

  /**
   * Go to home page
   */
  goHomePage() {
    this.router.navigate(["/home"]);
  }

  getAccount() {
    this.accountRequestsService.currentData$.subscribe((data: IAccounts) => {
      this.accountData = data;
    });
  }

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  /**
   * Get select language
   * @readonly
   * @type {Observable<Languages>}
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Get select accounts
   * @readonly
   * @type {Observable<IAccount>}
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  /**
   * Select account modality
   * @readonly
   * @type {Observable<IAutomaticRecharge>}
   */
  get selectAutomaticRecharge$(): Observable<IAutomaticRecharge> {
    return this.accountFacade.selectAutomaticRecharge$;
  }
}
