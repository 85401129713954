import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "@store/actions/app.actions";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "fp-app-successful-data-policy",
  templateUrl: "./successful-data-policy.component.html",
  styleUrls: ["./successful-data-policy.component.scss"],
})
export class SuccessfulDataPolicyComponent implements OnInit {
  @HostBinding("class") fpAppSuccessfulDataPolicy =
    "fp-app-successful-data-policy";
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
  }

  /**
   * actionClose
   *
   * @param $event boolean event
   */
  actionClose($event: boolean): void {
    this.router.navigate(["/signin/login"]);
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
