import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "@shared/services/authentication.service";
import { CommonsService } from "@shared/services/commons.service";
import { from, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return from(this.verifyToken(state.url.split("?")[0]));
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return from(this.verifyToken(state.url.split("?")[0]));
  }

  async verifyToken(route: string): Promise<boolean> {
    await this.authenticationService.getUserData();
    const user = await this.authenticationService.getCurrentAuthenticatedUser();
    if (!user) {
      this.commonService.navigate("/signin");
      return false;
    }

    const policyEnabled = await this.commonService.getPolicyEnabled();
    if (!policyEnabled) {
      this.commonService.navigate("data-policy/new-policy");
      return false;
    }

    const token = user.data.authenticationResult.idToken;
    if (token && !this.authenticationService.tokenExpired(token)) {
      if (user.data.result_message?.code === "100840") {
        if (route.includes("/complete-signup")) {
          return true;
        } else {
          this.commonService.navigate("/complete-signup");
          return false;
        }
      } else {
        if (route.includes("/complete-signup")) {
          this.commonService.navigate("/home");
          return false;
        } else {
          return true;
        }
      }
    } else {
      this.commonService.logOut(true, false);
      return false;
    }
  }
}
