import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LowBalanceGuard } from "./guards/low-balance.guard";
import { RecurrentRechargeGuard } from "./guards/recurrent-recharge.guard";
import { AccountStatusGuard } from "./guards/select-modality.guard";
import { AdminMenuRootRoute } from "./modules/admin-menu/admin-menu-routing.module";
import { AutomaticRechargeConfigRootRoute } from "./modules/automatic-recharge-config/automatic-recharge-config-routing.module";
import { FinancialProductRootRoute } from "./modules/financial-product/financial-product-routing.module";
import { LowBalanceRootRoute } from "./modules/low-balance/low-balance-routing.module";
import { PaymentMethodRootRoute } from "./modules/payment-method/payment-method-routing.module";
import { SelectModalityRootRoute } from "./modules/select-modality/select-modality-routing.module";
import { SuccessChangeModality } from "./modules/success-change-modality/success-change-modality-routing.module";
import { SuccessfulAssociationRootRoute } from "./modules/successful-association/successful-association-routing.module";
import { SuccessfulConfigurationRootRoute } from "./modules/successful-configuration/successful-configuration-routing.module";
import { SuccessfulProcessRootRoute } from "./modules/successful-process/successful-process-routing.module";
import { ValidateCardReference } from "./modules/validate-card-reference/validate-card-reference-routing.module";

export const accountRootRoute = "account";

const routes: Routes = [
  {
    path: "",
    redirectTo: AdminMenuRootRoute,
    pathMatch: "full",
  },
  {
    path: AdminMenuRootRoute,
    loadChildren: () =>
      import("./modules/admin-menu/admin-menu.module").then(
        (m) => m.AdminMenuModule,
      ),
    data: {
      permissions: {
        only: "ADMCTA",
      },
    },
  },
  {
    path: SelectModalityRootRoute,
    loadChildren: () =>
      import("./modules/select-modality/select-modality.module").then(
        (m) => m.SelectModalityModule,
      ),
    canActivate: [AccountStatusGuard],
    data: {
      permissions: {
        only: ["CUESEL", "CUEMOD"],
      },
    },
  },
  {
    path: FinancialProductRootRoute,
    loadChildren: () =>
      import("./modules/financial-product/financial-product.module").then(
        (m) => m.FinancialProductModule,
      ),
    data: {
      permissions: {
        only: "CUEMOD",
      },
    },
  },
  {
    path: SuccessfulAssociationRootRoute,
    loadChildren: () =>
      import(
        "./modules/successful-association/successful-association.module"
      ).then((m) => m.SuccessfulAssociationModule),
    data: {
      permissions: {
        only: "CUEMOD",
      },
    },
  },
  {
    path: SuccessfulProcessRootRoute,
    loadChildren: () =>
      import("./modules/successful-process/successful-process.module").then(
        (m) => m.SuccessfulProcessModule,
      ),
    data: {
      permissions: {
        only: ["CUESEL", "CUEMOD"],
      },
    },
  },
  {
    path: LowBalanceRootRoute,
    loadChildren: () =>
      import("./modules/low-balance/low-balance.module").then(
        (m) => m.LowBalanceModule,
      ),
    canActivate: [LowBalanceGuard],
    data: {
      permissions: {
        only: "CUECONSALB",
      },
    },
  },
  {
    path: SuccessfulConfigurationRootRoute,
    loadChildren: () =>
      import(
        "./modules/successful-configuration/successful-configuration.module"
      ).then((m) => m.SuccessfulConfigurationModule),
    canActivate: [LowBalanceGuard],
    data: {
      permissions: {
        only: "CUECONSALB",
      },
    },
  },
  {
    path: AutomaticRechargeConfigRootRoute,
    loadChildren: () =>
      import(
        "./modules/automatic-recharge-config/automatic-recharge-config.module"
      ).then((m) => m.AutomaticRechargeConfigModule),
    canActivate: [RecurrentRechargeGuard],
    data: {
      permissions: {
        only: ["CUESEL", "CUEMOD"],
      },
    },
  },
  {
    path: PaymentMethodRootRoute,
    loadChildren: () =>
      import("./modules/payment-method/payment-method.module").then(
        (m) => m.PaymentMethodModule,
      ),
    canActivate: [RecurrentRechargeGuard],
    data: {
      permissions: {
        only: ["CUESEL", "CUEMOD"],
      },
    },
  },
  {
    path: SuccessChangeModality,
    loadChildren: () =>
      import(
        "./modules/success-change-modality/success-change-modality.module"
      ).then((m) => m.SuccessChangeModalityModule),
    data: {
      permissions: {
        only: ["CUESEL", "CUEMOD"],
      },
    },
  },
  {
    path: ValidateCardReference,
    loadChildren: () =>
      import(
        "./modules/validate-card-reference/validate-card-reference.module"
      ).then((m) => m.ValidateCardReferenceModule),
    canActivate: [RecurrentRechargeGuard],
    data: {
      permissions: {
        only: ["CUESEL", "CUEMOD"],
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
