// all-data.selectors.ts

import { createSelector } from "@ngrx/store";
import { selectAccounts } from "./accounts.selector";
import { selectAddress } from "./address.selector";
import { selectLoginsData } from "./logins.selector";
import { selectUserInfo } from "./user-info.selector";

// Devuelves exactamente la forma esperada por tu backend
export const selectAllData = createSelector(
  selectUserInfo,
  selectAccounts,
  selectAddress,
  selectLoginsData,
  (userInfo, accounts, address, logins) => {
    return {
      userData: {
        ...userInfo.data,
        addresses: address.data, // asumiendo que addresses es un array
      },
      accountsData: accounts.data,
      loginsData: logins.data,
    };
  },
);
