import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { IUserData } from "../../models/user-data";
import { setUserDataPolicy } from "../actions/user-data.actions";
import { selectUserDataPolicy } from "../selectors/user-data.selector";
import { UserDataPolicyModuleState } from "../state/data-policy.state";

@Injectable({
  providedIn: "root",
})
export class DataPolicyFacade {
  public selectUserDataPolicy$: Observable<IUserData> = this.store.pipe(
    select(selectUserDataPolicy),
  );

  constructor(private store: Store<UserDataPolicyModuleState>) {}

  setDataUserByDataPolicy(dataUser: IUserData) {
    this.store.dispatch(setUserDataPolicy(dataUser));
  }
}
