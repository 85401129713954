import { createReducer, on } from "@ngrx/store";
import { loadAllDataSuccess } from "../actions/all-data.actions";
import { initialLoginState } from "../state/login.state";

export const loginsReducer = createReducer(
  initialLoginState,
  on(loadAllDataSuccess, (state, { logins }) => ({
    ...state,
    loading: false,
    loaded: true,
    data: logins,
  })),
);
