import { IUserData } from "../../models/user-data";

/**
 * Set a Name to read chart redux
 */
export const UserDataPolicyFeatureName = "userDataPolicyStateReducer";

export type UserDataPolicyModuleState = IUserData;

export const initialUserDataPolicy: IUserData = {
  names: "",
  lastNames: "",
  emailAddress: "",
  document: "",
  documentType: "",
  nit: "",
  dv: "",
  phoneNumber: "",
  referenceId: "",
};
