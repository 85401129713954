import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import { IAccounts } from "@app/models/all-data";
import { AESEncryptDecryptService } from "@app/modules/shared/services/aes-encrypt-decrypt.service";
import { AuthenticationService } from "@app/modules/shared/services/authentication.service";
import { NotifyService } from "@app/modules/shared/services/notify.service";
import { TranslateService } from "@ngx-translate/core";
import { CommonsService } from "@shared/services/commons.service";
import { IFinancialProducts } from "@shared-financial-data/models/financial-data";
import { SharedFinancialDataFacade } from "@shared-financial-data/store/facade/shared-financial-data.facade";
import { Languages } from "@store/actions/app.actions";
import { InputComponent } from "@theme/components/input/input.component";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import {
  IAccountInfo,
  IAccountInfoValidationMessages,
} from "../../models/account-info";
import { IAccountFinancialProduct } from "../../models/account-modality";
import {
  IEntity,
  IField,
  IProduct,
  IProductToAssociate,
} from "../../models/financial-product";
import { AccountNavigationService } from "../../services/account-navigation-service.service";
import { FinancialProductsService } from "../../services/financial-products.service";
import { AccountFacade } from "../../store/facade/account.facade";
@Component({
  selector: "fp-financial-product",
  templateUrl: "./financial-product.component.html",
  styleUrls: ["./financial-product.component.scss"],
})
export class FinancialProductComponent implements OnInit, OnDestroy {
  formFinancialProduct: UntypedFormGroup;
  productType: string = "";
  accountInfoValidationMessages: IAccountInfoValidationMessages;
  selectedAccount: string = "";
  availableEntities: IEntity[] = [
    {
      code: "1",
      entity: "Bogota",
      enabled: "true",
      products: [
        {
          name: "Cuenta",
          enabled: "true",
          fields: [
            {
              name: "Tipo cuenta",
              values: ["Corriente"],
            },
            {
              name: "Útimos 4 dígitos de la cuenta",
            },
          ],
        },
      ],
    },
  ];
  availableProducts: IProduct[];
  productOptions: IField[];
  accountSelected: IAccounts;
  viewMessageError: boolean = false;
  executionId: string = "";
  errorCode: string = "";
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild("entitySelect") entitySelect: MatSelect;
  @ViewChild("productSelect") productSelect: MatSelect;
  @ViewChild("creditCardNumber") creditCardNumber: InputComponent;
  @ViewChild("expMonth") expMonth: InputComponent;
  @ViewChild("notify", { static: true }) notify: TemplateRef<any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sharedFinancialDataFacade: SharedFinancialDataFacade,
    private commonsService: CommonsService,
    private appFacade: AppFacade,
    public translate: TranslateService,
    private snackBar: MatSnackBar,
    private accountFacade: AccountFacade,
    private aesEncryptDecrypt: AESEncryptDecryptService,
    private accountNavigationService: AccountNavigationService,
    private authenticationService: AuthenticationService,
    private notifyService: NotifyService,
    private financialProductsService: FinancialProductsService,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.closeNotify();
  }

  async ngOnInit(): Promise<any> {
    this.initForm();
    await this.getInitialState();
    await this.getAccountSelected();
  }
  initForm(): void {
    this.formFinancialProduct = this.formBuilder.group({
      entity: new UntypedFormControl("", [Validators.required]),
      financialProduct: new UntypedFormControl("", [Validators.required]),
      destinationAccount: new UntypedFormControl("", [Validators.required]),
      accountType: new UntypedFormControl("", [Validators.required]),
      lastDigits: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]{0,4}$"),
      ]),
      cardNumber: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9 ]{0,19}$"),
      ]),
      verificationCode: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]{0,4}$"),
      ]),
      expirationYear: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]{0,2}$"),
      ]),
      expirationMonth: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^(1[0-2]|0[1-9]|\\d)$"),
      ]),
    });
    this.accountInfoValidationMessages = {
      entity: [{ type: "required", message: "form_errors.entity.required" }],
      financialProduct: [
        {
          type: "required",
          message: "form_errors.financialProduct.required",
        },
        {
          type: "pattern",
          message: "form_errors.financialProduct.pattern",
        },
      ],
      lastDigits: [
        { type: "required", message: "form_errors.lastDigits.required" },
        { type: "pattern", message: "form_errors.lastDigits.pattern" },
        { type: "minlength", message: "form_errors.lastDigits.minLength" },
      ],
      accountType: [
        { type: "required", message: "form_errors.accountType.required" },
      ],
      destinationAccount: [
        {
          type: "required",
          message: "form_errors.destinationAccount.required",
        },
        { type: "pattern", message: "form_errors.destinationAccount.pattern" },
      ],
      cardNumber: [
        { type: "required", message: "form_errors.cardNumber.required" },
        { type: "minlength", message: "form_errors.cardNumber.minLength" },
        { type: "pattern", message: "form_errors.cardNumber.pattern" },
      ],
      verificationCode: [
        { type: "required", message: "form_errors.verificationCode.required" },
        {
          type: "minlength",
          message: "form_errors.verificationCode.minLength",
        },
        { type: "pattern", message: "form_errors.verificationCode.pattern" },
      ],
      expirationMonth: [
        { type: "required", message: "form_errors.expirationMonth.required" },
        { type: "pattern", message: "form_errors.expirationMonth.pattern" },
        { type: "minlength", message: "form_errors.expirationMonth.minLength" },
      ],
      expirationYear: [
        { type: "required", message: "form_errors.expirationYear.required" },
        { type: "pattern", message: "form_errors.expirationYear.pattern" },
        { type: "minlength", message: "form_errors.expirationYear.minLength" },
      ],
    };
  }
  setAccountInfo(accountInfo: IAccountInfo): void {
    this.accountFacade.setAccountFinancialProduct(
      this.aesEncryptDecrypt.encrypt(JSON.stringify(accountInfo)),
    );
  }
  saveAccountInfo(): void {
    this.setAccountInfo({
      ...this.formFinancialProduct.value,
    });
  }

  addLeadingZero($event): void {
    if (
      $event.key.toString().toLowerCase() === "backspace" ||
      $event.key.toString().toLowerCase() === "clear"
    ) {
      return;
    }
    let formattedValue: string;
    const inputValue = this.formFinancialProduct.controls.expirationMonth.value;
    const eventArr = ["0", "1", "2"];
    if (inputValue === "01" && eventArr.indexOf($event.key) >= 0) {
      formattedValue = ("0" + inputValue + $event.key).slice(-2);
    } else if (parseInt(inputValue) <= 12) {
      formattedValue = ("0" + inputValue).slice(-2);
    } else {
      return;
    }
    this.formFinancialProduct.controls.expirationMonth.setValue(formattedValue);
  }

  formAccountValidator(): boolean {
    const requiredFields =
      this.formFinancialProduct.controls.entity.value &&
      this.formFinancialProduct.controls.financialProduct.value;
    let optionsValidator = false;
    switch (this.productType) {
      case "Cuenta":
        optionsValidator =
          this.formFinancialProduct.controls.accountType.valid &&
          this.formFinancialProduct.controls.lastDigits.valid;
        break;
      case "Cupo de crédito":
        optionsValidator =
          this.formFinancialProduct.controls.cardNumber.valid &&
          this.formFinancialProduct.controls.lastDigits.valid;
        break;
      case "Tarjeta de crédito":
        optionsValidator =
          this.formFinancialProduct.controls.cardNumber.valid &&
          this.formFinancialProduct.controls.verificationCode.valid &&
          this.formFinancialProduct.controls.expirationMonth.valid &&
          this.formFinancialProduct.controls.expirationYear.valid;
        break;
    }
    return requiredFields && optionsValidator;
  }

  createProductInfoObject(username: string[]): any {
    const modalityInfo: IProductToAssociate = {
      personId: username[0],
      accountId: this.selectedAccount,
      accountType: !this.accountSelected.accountType ? "POS" : "",
      newAccountType: this.accountSelected.accountType ? "POS" : "",
      financialProductType: this.convertProductType(
        this.formFinancialProduct.controls.financialProduct.value,
      ),
      accountTypeFinancialProduct:
        this.formFinancialProduct.controls.accountType.value === "Corriente"
          ? "2"
          : "1",
      accountNumberFinancialProd: this.formFinancialProduct.controls.lastDigits
        .value,
      companyId: this.formFinancialProduct.controls.entity.value,
      segment: "pj",
    };
    return modalityInfo;
  }

  async continueFinancialProduct(): Promise<any> {
    this.saveAccountInfo();
    const username: string[] = (
      await this.authenticationService.getUsername()
    ).split("-");
    this.appFacade.setLoaderShow({
      type: "GENERAL",
    });
    const data = this.createProductInfoObject(username);
    try {
      this.clearModalityState();
      const associationRequest = !this.accountSelected.accountType
        ? await this.financialProductsService
            .postAssociateProduct(data)
            .toPromise()
        : await this.financialProductsService
            .postChangeModality(data)
            .toPromise();
      await this.checkModalityStatus(associationRequest.execution_id);
    } catch (error) {
      this.appFacade.setLoaderHide();
      this.showNotify();
    }
  }

  async checkModalityStatus(executionId: string): Promise<any> {
    try {
      const associationResult = !this.accountSelected.accountType
        ? await this.financialProductsService
            .getAssociateProduct(executionId)
            .toPromise()
        : await this.financialProductsService
            .getChangeModality(executionId)
            .toPromise();
      switch (associationResult.data.result_message.code) {
        case "102072": // Success
        case "102092": // Success
          this.clearModalityState();
          this.appFacade.setLoaderHide();
          this.accountNavigationService.next(2);
          break;
        case "100603": // Entity rejected
          const accountSelected = { ...this.accountSelected };
          accountSelected.isModalityChange =
            this.accountSelected.accountType !== "";
          accountSelected.rejectedAssociationCode =
            associationResult.data.result_message.message;
          accountSelected.accountType = "POS";
          this.appFacade.setAccountSelected(accountSelected);
          this.appFacade.setLoaderHide();
          this.accountNavigationService.next(2);
          break;
        case "102071": // Running
        case "102091": // Running change
          setTimeout(() => {
            this.checkModalityStatus(executionId);
          }, 1000);
          break;
        case "102073": // Machine failed
        case "102074": // Machine timeout
        case "102075": // Aborted
        case "102076": // Assign process doesn't exist
        case "100616": // Modality already assigned
        case "102093": // Machine failed
        case "102094": // Machine timeout
        case "102095": // Machine aborted
        case "102096": // Change process doesn't exist
        default:
          this.errorCode = associationResult.data.result_message.code;
          this.clearModalityState();
          this.appFacade.setLoaderHide();
          this.showNotify();
          break;
      }
    } catch (error) {
      this.appFacade.setLoaderHide();
      this.showNotify();
    }
  }

  clearModalityState() {
    this.accountFacade.resetsetAccountModality();
    this.accountFacade.resetsetAccountFinancialProduct();
  }

  convertProductType(productType: string): string {
    switch (productType) {
      case "Cuenta":
        return "ACCOUNT";
      case "Tarjeta de crédito":
        return "CREDIT CARD";
      case "Cupo de crédito":
        return "CREDIT QUOTA";
    }
  }

  showNotify(): void {
    this.notifyService.showNotify(this.notify, "fp-snack-modify");
  }
  closeNotify(): void {
    this.snackBar.dismiss();
  }

  async getAccountSelected(): Promise<any> {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccounts) => {
        this.accountSelected = action;
        this.selectedAccount = action?.accountId.toString();
      });
  }

  async getInitialState(): Promise<any> {
    const promiseFinancialProducts = await this.selectFinancialProducts$
      .pipe(take(1))
      .toPromise();

    if (promiseFinancialProducts.data === null) {
      this.sharedFinancialDataFacade.getFinancialProducts();
      this.selectFinancialProducts2$.pipe(take(2)).subscribe((entities) => {
        if (entities?.data !== null) {
          this.availableEntities = entities.data;
        } else if (entities.error !== null) {
          this.commonsService.navigate("error");
        }
      });
    } else {
      this.loadAccountFinancialProduct(promiseFinancialProducts?.data);
    }
  }
  loadAccountFinancialProduct(entities: IEntity[]): void {
    this.availableEntities = entities;
    this.selectAccountFinancialProduct$
      .pipe(take(1))
      .subscribe(({ data }: IAccountFinancialProduct) => {
        if (data) {
          const formFinancialProduct: IAccountInfo = JSON.parse(
            this.aesEncryptDecrypt.decrypt(data),
          );
          this.loadEntityChange(formFinancialProduct.entity);
          this.loadProductChange(formFinancialProduct.financialProduct);
          this.productType = formFinancialProduct.financialProduct;
          this.formFinancialProduct.setValue({
            entity: formFinancialProduct.entity,
            financialProduct: formFinancialProduct.financialProduct,
            destinationAccount: formFinancialProduct.destinationAccount,
            accountType: formFinancialProduct.accountType,
            lastDigits: formFinancialProduct.lastDigits,
            cardNumber: formFinancialProduct.cardNumber,
            verificationCode: formFinancialProduct.verificationCode,
            expirationMonth: formFinancialProduct.expirationMonth,
            expirationYear: formFinancialProduct.expirationYear,
          });
        }
      });
  }

  entityChange(entityCode): void {
    this.formFinancialProduct.controls.financialProduct.reset();
    this.productType = "";
    this.chooseAccountType("");
    this.loadEntityChange(entityCode);
  }

  chooseAccountType(accountType: string): void {
    if (accountType !== "") {
      this.formFinancialProduct.controls.accountType.setValue(accountType);
      this.saveAccountInfo();
    }
  }

  loadEntityChange(entityCode: string): void {
    this.availableProducts = (this.availableEntities
      ?.find((x) => x.code === entityCode)
      ?.products.filter((x) => x.enabled === "true") as any) as IProduct[];

    this.formFinancialProduct.controls.entity.markAsDirty();
    this.formFinancialProduct.controls.entity.markAsTouched();

    if (this.availableProducts?.length === 1) {
      this.productChange(this.availableProducts[0].name);
      this.formFinancialProduct.controls.financialProduct.setValue(
        this.availableProducts[0].name,
      );
      this.formFinancialProduct.controls.financialProduct.markAsDirty();
      this.formFinancialProduct.controls.financialProduct.markAsTouched();
    }
  }

  productChange(value): void {
    this.productType = value;
    this.loadProductChange(value);
    this.resetProductOptionsFields();
    this.setDefaultAccountType();
  }

  setDefaultAccountType(): void {
    const entity = this.availableEntities?.find(
      (x) => x.code === this.formFinancialProduct.controls.entity.value,
    );
    if (
      entity &&
      this.productType === "Cuenta" &&
      (entity.entity.indexOf("Occidente") > -1 ||
        entity.entity.indexOf("Bogo") > -1)
    ) {
      this.chooseAccountType("Corriente");
    }
  }

  resetProductOptionsFields(): void {
    this.formFinancialProduct.controls.cardNumber.reset();
    this.formFinancialProduct.controls.verificationCode.reset();
    this.formFinancialProduct.controls.expirationMonth.reset();
    this.formFinancialProduct.controls.expirationYear.reset();
    this.formFinancialProduct.controls.lastDigits.reset();
    this.formFinancialProduct.controls.accountType.reset();
  }

  loadProductChange(value): void {
    if (value) {
      this.productOptions = (this.availableProducts?.find(
        (x) => x.name === value,
      ).fields as any) as IField[];
    } else {
      return;
    }
  }
  updateCardNumber(): void {
    const formattedValue = this.formFinancialProduct.controls.cardNumber.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    this.formFinancialProduct.controls.cardNumber.setValue(formattedValue);
    const noSpacesValue = this.formFinancialProduct.controls.cardNumber.value.replace(
      /\s/g,
      "",
    );
    this.viewMessageError = noSpacesValue.length < 16 ? true : false;
  }

  inputEvent($event): void {
    if (
      $event.inputType === "insertFromPaste" ||
      $event.inputType === "insertText"
    ) {
      if (this.formFinancialProduct.controls.cardNumber.value.length < 19) {
        this.viewMessageError = true;
        this.setErrorcardNumber();
      } else {
        this.viewMessageError = false;
      }
    }
  }

  setErrorcardNumber(): void {
    this.formFinancialProduct.get("cardNumber").setErrors({ pattern: true });
    this.formFinancialProduct.get("cardNumber").markAsTouched();
  }

  get selectFinancialProducts$(): Observable<IFinancialProducts> {
    return this.sharedFinancialDataFacade.selectFinancialProducts$;
  }

  get selectFinancialProducts2$(): Observable<IFinancialProducts> {
    return this.sharedFinancialDataFacade.selectFinancialProducts$;
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  get selectAccountSelected$(): Observable<IAccounts> {
    return this.appFacade.selectAccountSelected$;
  }

  get selectAccountFinancialProduct$(): Observable<IAccountFinancialProduct> {
    return this.accountFacade.selectAccountFinancialProduct$;
  }
}
