import { createAction } from "@ngrx/store";
import { IUserData } from "../../models/user-data";

export const enum TypeActionsUserData {
  selectDataUserString = "[DATA-POLICY set data user] set information",
  selectDataUserResetString = "[COMPLETE DATA-POLICY set data user] Reset data",
  getDataUserString = "[DATA-POLICY get data user] get information",
}
/**
 * Action to set user data
 */
export const setUserDataPolicy = createAction(
  TypeActionsUserData.selectDataUserString,
  (userData: IUserData) => ({ userData }),
);

/**
 * Action to get data
 */
export const getUserDataPolicy = createAction(
  TypeActionsUserData.getDataUserString,
);
