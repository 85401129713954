import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IAccounts, IUserInfo } from "@app/models/all-data";
import { Observable, combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AccountStatusGuard implements CanActivate {
  private allowedStatuses: string[] = [
    "18007",
    "18012",
    "18019",
    "18021",
    "18023",
  ];

  constructor(private appFacade: AppFacade, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return combineLatest([
      this.appFacade.selectAccountSelected$,
      this.appFacade.selectUserInfo$,
    ]).pipe(
      take(1),
      map(([account, userInfo]: [IAccounts, IUserInfo]) => {
        const validAccount = account && account.statusAccountCod === "18151";
        const validStatus =
          userInfo &&
          this.allowedStatuses.includes(userInfo.data.currentStatus);

        const allowed = validAccount && validStatus;

        return allowed;
      }),
    );
  }
}
