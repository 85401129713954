import { createReducer, on } from "@ngrx/store";
import * as addressActions from "../actions/address.actions";
import { loadAllDataSuccess } from "../actions/all-data.actions";
import { initialAddressState } from "../state/address.state";

export const addressReducer = createReducer(
  initialAddressState,
  on(addressActions.addressLoad, (state) => ({
    ...state,
    data: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(addressActions.addressSave, (state, { data }) => ({
    ...state,
    data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(addressActions.addressError, (state, { error }) => ({
    ...state,
    data: null,
    error,
    loaded: false,
    loading: false,
  })),
  on(addressActions.addressReset, () => ({
    ...initialAddressState,
  })),
  on(loadAllDataSuccess, (state, { address }) => ({
    ...state,
    loading: false,
    loaded: true,
    data: address,
  })),
);
