import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { ITemporalPassword } from "@app/models/temporal-password";
import { IUser } from "@app/models/user";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "@environment";
import { AESEncryptDecryptService } from "@shared/services/aes-encrypt-decrypt.service";
import { StorageService } from "@shared/services/storage.service";
import { IVinculateModel } from "@shared-sign/generic-otp/models/otpcode";
import { SignUpFacade } from "@signup/store/facade/signup.facade";
import JSEncrypt from "jsencrypt";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, throwError } from "rxjs";
import { catchError, take } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
/**
 * Service to connect with AWS Cognito
 */
export class AuthenticationService {
  get selectTemporalPassword$(): Observable<ITemporalPassword> {
    return this.signUpFacade.selectTemporalPassword$;
  }
  refreshed: boolean = true;
  encrypt = new JSEncrypt({});
  private differenceSecond: number;
  private cognitoUser: IUser;

  constructor(
    private aesEncryptDecryptService: AESEncryptDecryptService,
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private jwtHelperService: JwtHelperService,
    private signUpFacade: SignUpFacade,
    private permissionsService: NgxPermissionsService,
  ) {
    this.encrypt.setPublicKey(environment.facilpasskeyPublic);
  }

  /**
   * Cognito Signup Integration
   * @param dataSingUp params to sent a register on Cognito
   */
  public signUp(dataSingUp: IVinculateModel): Promise<any> {
    const phone = dataSingUp.personToSave.phoneNumber;
    const password = dataSingUp.personToSave.password;
    dataSingUp.personToSave.password = this.encrypt.encrypt(password) as string;
    dataSingUp.personToSave.personType = "pj";
    return this.http
      .post(
        environment.api_key.securityPN.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.signup,
        {
          personToSave: {
            ...dataSingUp.personToSave,
            phoneNumber: `+57${phone}`,
          },
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * Delete Cognito User
   * @param nickname nickname
   */
  public deleteCognitoUser(nickname: string): Promise<any> {
    return this.http
      .delete(
        environment.api_key.securityPN.back_url +
          environment.endpoints.usersession.specific.admindeleteuser +
          "/" +
          nickname,
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * Cognito signIn Integration
   * @param username Username
   * @param password Optional password
   */
  public async signIn(username: string, password?: string): Promise<any> {
    if (!password) {
      const promiseTemporalPassword = await this.selectTemporalPassword$
        .pipe(take(1))
        .toPromise();
      password = this.encrypt.encrypt(
        this.aesEncryptDecryptService.decrypt(
          promiseTemporalPassword.temporalPassword,
        ),
      ) as string;
    } else {
      password = this.encrypt.encrypt(password) as string;
    }
    this.cognitoUser = await this.http
      .post<IUser>(
        environment.api_key.securityPN.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.login,
        {
          authParameters: {
            username,
            password,
          },
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
    const decodeToken = this.jwtHelperService.decodeToken(
      this.cognitoUser?.data.authenticationResult.idToken,
    );
    this.permissionsService.addPermission(decodeToken.actions.split(","));
    this.permissionsService.loadPermissions(decodeToken.actions.split(","));
    this.calcDifferenceTime(decodeToken.iat);
    this.cognitoUser.data.authenticationResult.nickname = decodeToken.nickname?.split(
      "-",
    )[0];
    this.storageService.setItem("user", JSON.stringify(this.cognitoUser));
    return this.cognitoUser;
  }

  /**
   * Get current User
   */
  public resetCognitoUser(): void {
    this.cognitoUser = null;
  }
  public async getCurrentAuthenticatedUser(): Promise<IUser> {
    this.resetCognitoUser();
    try {
      this.cognitoUser = JSON.parse(await this.storageService.getItem("user"));
    } catch (error) {}
    return this.cognitoUser;
  }
  /**
   * SingOut
   */
  public async signOut(): Promise<any> {
    const userCognito = await this.getCurrentAuthenticatedUser();
    if (userCognito && userCognito.data) {
      try {
        await this.http
          .post(
            environment.api_key.securityPN.back_url +
              environment.endpoints.usersession.init +
              environment.endpoints.usersession.specific.logout,
            {
              token: userCognito.data.authenticationResult.accessToken,
              type: "LOGOUT",
            },
            {
              params: new CustomHttpParams("api-pn-security-apiKey-token"),
            },
          )
          .toPromise();
      } catch (error) {
      } finally {
        this.cognitoUser = null;
        localStorage.clear();
        this.storageService.removeAll();
      }
    }
  }

  /**
   * Confirm Singup
   * @param nickname Param to confirm singUp
   * @param code Code sended
   */
  public confirmSignUp(nickname: string, code: string): Promise<any> {
    return this.http
      .post(
        environment.api_key.securityPN.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.confirm,
        {
          personId: nickname,
          confirmationCode: code,
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * Method to sendOtp
   * @param nickname Param to send a Otp
   */
  public sendOtp(
    nickname: string,
    email: string = null,
    phoneNumber: string = null,
    names: string = null,
  ): Promise<any> {
    const data: any = {};
    data.personId = nickname;
    if (email !== null) {
      data.email = email;
    }
    if (phoneNumber !== null) {
      data.phoneNumber = `+57${phoneNumber}`;
    }
    if (names !== null) {
      data.names = names;
    }
    return this.http
      .post(
        environment.api_key.securityPN.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.resendconfirmationcode,
        {
          ...data,
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }

  /**
   * refresh user token
   */
  public async refreshToken(): Promise<any> {
    if (this.refreshed) {
      this.refreshed = false;
      const userCognito = await this.getCurrentAuthenticatedUser();
      const userName = await this.getUsername();
      this.http
        .post<any>(
          environment.api_key.securityPN.back_url +
            environment.endpoints.usersession.init +
            environment.endpoints.usersession.specific.refresh,
          {
            idToken: userCognito.data.authenticationResult.idToken,
            personId: userName,
            refreshToken: userCognito.data.authenticationResult.refreshToken,
            deviceKey: userCognito.data.authenticationResult.tokenType,
          },
          {
            headers: {
              "x-api-key": this.aesEncryptDecryptService.decrypt(
                environment.api_key.securityPN.x_api_key,
              ),
              "request-uuid": uuidv4() + "|" + new Date().getTime(),
              "request-date": new Date().toDateString(),
              // eslint-disable-next-line quote-props
              ctype: "pn",
            },
          },
        )
        .pipe(
          catchError(({ error }) => {
            return throwError(error);
          }),
        )
        .subscribe(
          (data) => {
            this.refreshed = true;
            const decodeToken = this.jwtHelperService.decodeToken(
              this.cognitoUser?.data.authenticationResult.idToken,
            );
            this.permissionsService.addPermission(
              decodeToken.actions.split(","),
            );
            this.setTokenUser(data.initiateAuthResult);
          },
          (error) => {
            // sesion activa caso sesiones huerfanas
            if (error.data.code === "100582" || error.data.code === "100581") {
              this.cognitoUser = null;
              this.storageService.removeAll();
              this.router.navigate(["/signout"]);
            }
            this.refreshed = true;
          },
        );
    }
  }
  /**
   * Method to start process to recover password
   * @param nickname nickname
   */
  public async forgotPassword(nickname: string): Promise<any> {
    return this.http
      .get(
        environment.api_key.securityPN.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.forgotpassword +
          "?personId=" +
          nickname,
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * method to confirm ForgotPassword
   * @param nickname nickname
   * @param code otpCode
   * @param newpassword newPassword
   */
  public async forgotPasswordSubmit(
    nickname: string,
    code: string,
    newpassword: string,
  ): Promise<any> {
    return this.http
      .post(
        environment.api_key.securityPN.back_url +
          environment.endpoints.usersession.init +
          environment.endpoints.usersession.specific.confirmforgotpassword,
        {
          personId: nickname,
          password: this.encrypt.encrypt(newpassword),
          confirmationCode: code,
        },
        {
          params: new CustomHttpParams("api-pn-security-apiKey-catpcha"),
        },
      )
      .toPromise();
  }
  /**
   * get UserName from decodeToken
   */
  async getUsername(): Promise<string> {
    await this.getCurrentAuthenticatedUser();
    const decodeToken = this.jwtHelperService.decodeToken(
      this.cognitoUser?.data?.authenticationResult.idToken,
    );
    return decodeToken.nickname;
  }

  /**
   * get personId from decodeToken
   */
  async getPersonId(): Promise<string> {
    await this.getCurrentAuthenticatedUser();
    const decodeToken = this.jwtHelperService.decodeToken(
      this.cognitoUser?.data.authenticationResult.idToken,
    );
    return decodeToken.person_id;
  }

  /**
   * get User Data on token payload
   */
  async getUserData(): Promise<any> {
    await this.getCurrentAuthenticatedUser();
    const decodeToken = this.jwtHelperService.decodeToken(
      this.cognitoUser?.data?.authenticationResult?.idToken,
    );
    if (decodeToken) {
      this.permissionsService.addPermission(decodeToken.actions.split(","));
      this.permissionsService.loadPermissions(decodeToken.actions.split(","));
    }
    return decodeToken;
  }

  public tokenExpired(token: string): boolean {
    const expiry =
      this.jwtHelperService.decodeToken(token).exp * 1000 +
      this.getDifferenceSecond();
    return new Date().getTime() >= expiry;
  }

  getPasswordEncrypted(password: string): string {
    return this.encrypt.encrypt(password) as string;
  }
  async calculateTimeExpiration(): Promise<any> {
    const exp =
      this.jwtHelperService.decodeToken(
        this.cognitoUser?.data.authenticationResult.idToken,
      ).exp * 1000;
    return exp + this.getDifferenceSecond() - new Date().getTime();
  }
  /**
   * set User Token
   * @param data data
   */
  private async setTokenUser(data: any) {
    const userCognito = await this.getCurrentAuthenticatedUser();
    userCognito.data.authenticationResult.accessToken = data.accessToken;
    userCognito.data.authenticationResult.expiresIn = data.expiresIn;
    userCognito.data.authenticationResult.idToken = data.idToken;
    userCognito.data.authenticationResult.tokenType = data.tokenType;
    this.storageService.setItem("user", JSON.stringify(userCognito));
  }

  private calcDifferenceTime(iat: number): void {
    const iatDate = iat * 1000;
    const actualDate = new Date().getTime();
    const difference = actualDate - iatDate;
    this.differenceSecond = difference;
    localStorage.setItem("differenceTime", this.differenceSecond.toString());
  }

  private getDifferenceSecond(): number {
    if (this.differenceSecond) {
      return this.differenceSecond;
    } else {
      this.differenceSecond = Number(localStorage.getItem("differenceTime"));
    }
    return this.differenceSecond;
  }
}
