import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import { joiValidator, emailSchema } from "@app/helpers/validator.helper";
import { IAccount } from "@app/models/all-data";
import { ICities, IDataCities } from "@app/modules/shared/models/cities";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { DocumentsService } from "@app/modules/signup/services/documents.service";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { Languages } from "@app/store/actions/app.actions";
import { environment } from "@environment";
import { TranslateService } from "@ngx-translate/core";
import { InputComponent } from "@theme/components/input/input.component";
import { PaymentComponent } from "@theme/components/payment/payment.component";
import { SnackMessageOptions } from "@theme/components/snack-message/snack-message.component";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import {
  ICreateTransaction,
  IUpdateTransactionRequest,
  Payment,
  PaymentMethod,
} from "../../models/transaction.interface";
import { TransactionsService } from "../../services/transactions.service";

/**
 * Recharge Component
 */
@Component({
  selector: "fp-recharge-transaction",
  templateUrl: "./unique-recharge.component.html",
  styleUrls: ["./unique-recharge.component.scss"],
})
export class UniqueRechargeComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpUniqueRecharge = "fp-unique-recharge";
  @ViewChild("reminder", { static: true }) tReminder: TemplateRef<any>;
  @ViewChild("notify", { static: true }) notify: TemplateRef<any>;
  @ViewChild("inputAmount") inputAmount: InputComponent;
  @ViewChild("inputIdNumber") inputIdNumber: InputComponent;
  @ViewChild("inputPhone") inputPhone: InputComponent;
  @ViewChild("inputEmail") inputEmail: InputComponent;
  @ViewChild("inputAutocomplete") inputAutocomplete: InputComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();
  formRecharge: UntypedFormGroup;
  minAmount: number = Number(environment.pse.minimumAmount);
  typeMessage: SnackMessageOptions = "infoOnlyDescription";

  @ViewChild(PaymentComponent)
  paymentComponent: PaymentComponent;
  entitie: string;
  viewMessageError: boolean;
  timeOut: any;
  typeModal = "avalPay";
  avalPayLink: any;
  pendingTransactionMessage = "";
  inputClass: string = "input-large autocomplete-open";
  cities: IDataCities[];

  transactionDetail: ICreateTransaction;
  paymentMethods: PaymentMethod[];
  payment: Payment;

  showDetail: boolean = false;
  paymentMethod: string;
  accountType: string;

  sending: boolean = false;

  documentTypes = [
    { label: "NIT", value: "NIT" },
    { label: "C.C", value: "CC" },
    { label: "C.E", value: "CE" },
  ];

  documentTypeSelected: string;

  labelName: string = "";
  labelPhone: string = "";
  initialData = {};
  showPolicySection: boolean = false;

  patternDocument: string;

  get RechargeErrorMessages() {
    return {
      documentNumber: [
        {
          type: "required",
          message: "form_errors.documentNumber.required",
        },
        {
          type: "pattern",
          message: `form_errors.documentNumber.${this.patternDocument}`,
        },
      ],
      name: [
        {
          type: "required",
          message: "form_errors.businessName.required",
        },
        {
          type: "pattern",
          message: "form_errors.businessName.pattern",
        },
        {
          type: "minlength",
          message: "form_errors.businessName.minlength",
        },
        {
          type: "maxlength",
          message: "form_errors.businessName.maxlength",
        },
      ],
      cities: [
        { type: "required", message: "form_errors.cities.required" },
        { type: "pattern", message: "form_errors.cities.pattern" },
        {
          type: "option_invalid",
          message: "form_errors.cities.invalid",
        },
      ],
      email: [
        {
          type: "required",
          message: "form_errors.email.register_error_required",
        },
        {
          type: "joi",
          message: "form_errors.email.register_error_email",
        },
      ],
      confirmEmail: [
        {
          type: "required",
          message: "form_errors.confirmEmail.register_error_required",
        },
        {
          type: "joi",
          message: "form_errors.confirmEmail.register_error_email",
        },
        {
          type: "emailMismatch",
          message: "form_errors.confirmEmail.email_mismatch",
        },
      ],
      phone: [
        {
          type: "required",
          message: "form_errors.phone.required",
        },
        {
          type: "pattern",
          message: "form_errors.phone.pattern",
        },
        {
          type: "minlength",
          message: "form_errors.phone.minlength",
        },
      ],
      address: [
        { type: "required", message: "form_errors.cities.required" },
        { type: "pattern", message: "form_errors.address_fields.pattern" },
      ],
      businessName: [
        {
          type: "required",
          message: "form_errors.businessName.required",
        },
        {
          type: "pattern",
          message: "form_errors.businessName.pattern",
        },
        {
          type: "minlength",
          message: "form_errors.businessName.minlength",
        },
        {
          type: "maxlength",
          message: "form_errors.businessName.maxlength",
        },
      ],
    };
  }

  transactionData: ICreateTransaction;

  transactionId: string;

  subtitleMessage: string = "label_subtitle_pse";

  urlDataPolicy: string = "";
  versionDataPolicy: string = "";

  /**
   * Construtor
   *
   * @param snackBar MatSnackBar
   * @param appFacade AppFacade
   * @param window Window
   * @param pseService PseService
   */
  constructor(
    public translate: TranslateService,
    private snackBar: MatSnackBar,
    private appFacade: AppFacade,
    private modalService: ModalService,
    private transactionsService: TransactionsService,
    private commonsService: CommonsService,
    private documentService: DocumentsService,
  ) {
    this.patternDocument = "";
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
  }

  /**
   * Init state to component
   */
  async ngOnInit(): Promise<void> {
    this.getTransactionInfo();
    this.getAccounts();
    this.getCities();
    this.loadHabeasData();
    this.initForm();
    this.setupFormValueChanges();
  }
  /**
   * Destroy component
   */
  ngOnDestroy() {
    this.snackBar.dismiss();
    clearTimeout(this.timeOut);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initForm() {
    this.formRecharge = new UntypedFormGroup(
      {
        paymentMethod: new UntypedFormControl([Validators.required]),
        amount: new UntypedFormControl(this.payment.totalAmount, [
          Validators.required,
          Validators.pattern("^[0-9.$ ]+$"),
          Validators.minLength(7),
        ]),
        identificationType: new UntypedFormControl(
          this.payment.clientDocumentType,
          [Validators.required],
        ),
        identificationNumber: new UntypedFormControl(
          this.payment.clientDocument,
          [Validators.required, Validators.pattern("^[0-9]{6,10}$")],
        ),
        name: new UntypedFormControl(this.payment.clientFullName, [
          Validators.required,
          Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ&0-9' ]+$"),
        ]),
        firstName: new UntypedFormControl(this.payment.clientName, [
          Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ ]{2,60}$"),
        ]),
        lastName: new UntypedFormControl(this.payment.clientSurname, [
          Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ ]{2,60}$"),
        ]),
        phone: new UntypedFormControl(this.payment.clientPhone, [
          Validators.required,
          Validators.pattern("^[3]{1}[0-9]{9}$"),
        ]),
        email: new UntypedFormControl(this.payment.clientEmail, [
          Validators.required,
          joiValidator(emailSchema),
        ]),
        confirmEmail: new UntypedFormControl(this.payment.clientEmail, [
          Validators.required,
          joiValidator(emailSchema),
        ]),
        city: new UntypedFormControl("", [
          Validators.required,
          Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ\\-,-. ]{2,50}$"),
        ]),
        address: new UntypedFormControl(this.payment.billingAddress, [
          Validators.required,
        ]),
        policy: new UntypedFormControl({ value: false, disabled: true }),
      },
      { validators: this.emailMatchValidator() },
    );

    this.formRecharge
      .get("identificationType")
      .valueChanges.subscribe((type) => {
        this.updateNameValidators(type);
      });

    // Inicializar validaciones y etiquetas
    const initialType = this.formRecharge.get("identificationType").value;
    this.updateNameValidators(initialType);
    this.selectDocumentType(initialType);

    // Capturar los valores iniciales de los campos
    this.initialData["identificationType"] = this.formRecharge.get(
      "identificationType",
    ).value;
    this.initialData["identificationNumber"] = this.formRecharge.get(
      "identificationNumber",
    ).value;
    this.initialData["name"] = this.formRecharge.get("name").value;
    this.initialData["firstName"] = this.formRecharge.get("firstName").value;
    this.initialData["lastName"] = this.formRecharge.get("lastName").value;
    this.initialData["phone"] = this.formRecharge.get("phone").value;
    this.initialData["email"] = this.formRecharge.get("email").value;
    this.initialData["confirmEmail"] = this.formRecharge.get(
      "confirmEmail",
    ).value;
    this.initialData["address"] = this.formRecharge.get("address").value;
  }

  setupFormValueChanges() {
    const fieldsToWatch = ["identificationType", "identificationNumber"];
    let isUpdating = false;

    fieldsToWatch.forEach((field) => {
      this.formRecharge.get(field).valueChanges.subscribe(() => {
        if (isUpdating) {
          return;
        }

        const currentIdentificationType = this.formRecharge.get(
          "identificationType",
        ).value;
        const currentIdentificationNumber = this.formRecharge.get(
          "identificationNumber",
        ).value;

        isUpdating = true;

        if (
          currentIdentificationType ===
            this.initialData["identificationType"] &&
          currentIdentificationNumber ===
            this.initialData["identificationNumber"]
        ) {
          this.showPolicySection = false;
          this.formRecharge.get("policy").clearValidators();
          this.formRecharge.get("policy").disable();
          this.formRecharge.get("policy").setValue(false);
          this.formRecharge.get("policy").updateValueAndValidity();

          this.formRecharge.get("phone").setValue(this.initialData["phone"]);
          this.formRecharge.get("email").setValue(this.initialData["email"]);
          this.formRecharge
            .get("confirmEmail")
            .setValue(this.initialData["confirmEmail"]);
          this.formRecharge.get("city").setValue(this.initialData["city"]);
          this.formRecharge
            .get("address")
            .setValue(this.initialData["address"]);

          this.formRecharge.get("phone").updateValueAndValidity();
          this.formRecharge.get("email").updateValueAndValidity();
          this.formRecharge.get("confirmEmail").updateValueAndValidity();
          this.formRecharge.get("city").updateValueAndValidity();
          this.formRecharge.get("address").updateValueAndValidity();

          if (currentIdentificationType === "NIT") {
            this.formRecharge.get("name").setValue(this.initialData["name"]);
            this.formRecharge.get("firstName").setValue("");
            this.formRecharge.get("lastName").setValue("");
            this.formRecharge.get("name").updateValueAndValidity();
          } else {
            this.formRecharge.get("name").setValue("");
            this.formRecharge
              .get("firstName")
              .setValue(this.initialData["firstName"]);
            this.formRecharge
              .get("lastName")
              .setValue(this.initialData["lastName"]);
            this.formRecharge.get("firstName").updateValueAndValidity();
            this.formRecharge.get("lastName").updateValueAndValidity();
          }
        } else {
          this.showPolicySection = true;
          this.formRecharge
            .get("policy")
            .setValidators([Validators.requiredTrue]);
          this.formRecharge.get("policy").enable();
          this.formRecharge.get("policy").updateValueAndValidity();

          this.formRecharge.get("name").setValue("");
          this.formRecharge.get("firstName").setValue("");
          this.formRecharge.get("lastName").setValue("");
          this.formRecharge.get("phone").setValue("");
          this.formRecharge.get("email").setValue("");
          this.formRecharge.get("confirmEmail").setValue("");
          this.formRecharge.get("city").setValue("");
          this.formRecharge.get("address").setValue("");
        }
        isUpdating = false;
      });
    });
  }

  updateNameValidators(documentTypeValue: string) {
    const nameControl = this.formRecharge.get("name");

    if (documentTypeValue === "NIT") {
      nameControl.setValidators([
        Validators.required,
        Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ&0-9' ]+$"),
        Validators.minLength(3),
        Validators.maxLength(60),
      ]);
      this.formRecharge.get("firstName").clearValidators();
      this.formRecharge.get("lastName").clearValidators();
    } else {
      nameControl.setValidators([
        Validators.required,
        Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ ]{2,60}$"),
        Validators.minLength(3),
        Validators.maxLength(60),
      ]);

      this.formRecharge.get("name").clearValidators();
      this.formRecharge
        .get("firstName")
        .setValidators([
          Validators.required,
          Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ ]{2,60}$"),
        ]);
      this.formRecharge
        .get("lastName")
        .setValidators([
          Validators.required,
          Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ ]{2,60}$"),
        ]);
    }
    nameControl.updateValueAndValidity();
    this.formRecharge.get("firstName").updateValueAndValidity();
    this.formRecharge.get("lastName").updateValueAndValidity();
  }

  /**
   * Select document type
   * @param value string
   */
  selectDocumentType(value: string) {
    this.documentTypeSelected = value;
    this.formRecharge.get("identificationType")?.setValue(value);

    const idNumberControl = this.formRecharge.get("identificationNumber");

    if (value === "CE") {
      this.patternDocument = "pattern_ce";
      idNumberControl.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]{3,7}$"),
      ]);
    } else if (value === "NIT") {
      this.patternDocument = "pattern";
      idNumberControl.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]{6,10}$"),
      ]);
    } else {
      this.patternDocument = "pattern_cc";
      idNumberControl.setValidators([
        Validators.required,
        Validators.pattern("^[0-9]{3,10}$"),
      ]);
    }

    if (this.paymentMethod) {
      // Aplicar validaciones de inmediato
      idNumberControl.updateValueAndValidity();
      idNumberControl.markAsTouched();
      idNumberControl.markAsDirty();
      this.inputIdNumber.updateInput();
    }

    // Si tienes un método para actualizar el input:

    if (value === "NIT") {
      this.labelName = "label_company_name";
      this.labelPhone = "label_company_phone";
    } else {
      this.labelName = "label_name";
      this.labelPhone = "label_phone";
    }
  }

  getTransactionInfo() {
    this.transactionDetail = JSON.parse(
      sessionStorage.getItem("transactionDetail"),
    );

    this.payment = this.transactionDetail.payment;

    this.paymentMethods = this.transactionDetail.paymentMethods;

    sessionStorage.setItem("customToken", this.transactionDetail.token);
  }

  private emailMatchValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const emailBilling = group.get("email");
      const confirmEmail = group.get("confirmEmail");

      if (emailBilling?.value !== confirmEmail?.value) {
        confirmEmail.setErrors({ emailMismatch: true });
        return { emailMismatch: true };
      } else {
        if (confirmEmail.hasError("emailMismatch")) {
          confirmEmail.setErrors(null);
        }
        return null;
      }
    };
  }

  choosePaymentMethod(paymentMethodId: string, name: string): void {
    this.showDetail = true;
    this.formRecharge.controls.paymentMethod.setValue(paymentMethodId);
    this.paymentMethod = name;

    this.formRecharge.markAllAsTouched();
    this.formRecharge.markAsDirty();
    this.formRecharge.updateValueAndValidity();

    this.subtitleMessage =
      this.paymentMethod == "PSE"
        ? "label_subtitle_pse"
        : "label_subtitle_card";

    setTimeout(() => {
      this.setDefaultCity();
    }, 100);
  }

  /**
   * Selection Bank Change
   *
   * @param entitie Name Bankc
   */
  onChangeBank(entitie: string) {
    this.entitie = entitie;
  }
  /**
   * Create Transaction Recharge
   */
  async updateTransaction() {
    const values = this.formRecharge.value;
    const [city, department] = values.city.split(" - ");
    const dataProcessingPolicy =
      this.showPolicySection && this.formRecharge.get("policy").value;

    const request: IUpdateTransactionRequest = {
      clientDocumentType: values.identificationType,
      clientDocument: values.identificationNumber,
      clientMobilePhone: values.phone,
      clientEmail: values.email,
      billingCity: city,
      billingProvince: department,
      billingCityCode: this.inputAutocomplete?.optionSelected.value,
      billingCountry: "CO",
      billingAddress: values.address,
      paymentMethod: values.paymentMethod,
      dataProcessingPolicy: dataProcessingPolicy,
    };

    if (values.name) {
      request.clientFullName = values.name;
    } else if (values.firstName && values.lastName) {
      request.clientName = values.firstName;
      request.clientSurname = values.lastName;
    }

    if (dataProcessingPolicy) {
      request.versionDataPolicy = this.versionDataPolicy;
      request.urlDataPolicy = this.urlDataPolicy;
    }

    this.appFacade.setLoaderShow({
      type: "GENERAL",
    });
    await this.transactionsService
      .updateTransaction(this.payment.id, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.closeModal();
          if (response.data?.link) {
            location.href = response.data.link;
          }
        },
        error: (error) => {
          this.transactionId = this.payment.id;
          this.appFacade.setLoaderHide();
          this.showError();

          if (
            error.error.message &&
            error.error.message.includes("ya fue enviado al proveedor de pagos")
          ) {
            setTimeout(() => {
              this.commonsService.navigate("/transactions/recharge");
            }, 5000);
          }
        },
      });
  }

  showError(): void {
    this.snackBar.openFromTemplate(this.notify, {
      horizontalPosition: "center",
      panelClass: "fp-snack",
      verticalPosition: "top",
    });
  }

  setDefaultCity() {
    const currentCity = `${this.payment.billingCity.trim()} - ${this.payment.billingProvince.trim()}`;
    const dataCity = this.cities.find(
      (city) => city.description.toLowerCase() === currentCity.toLowerCase(),
    );

    if (dataCity) {
      this.formRecharge.controls.city.setValue(dataCity.description);

      if (!this.initialData["city"]) {
        this.initialData["city"] = dataCity.description;
      }
      if (this.inputAutocomplete) {
        this.inputAutocomplete.optionSelected = dataCity;
        this.inputAutocomplete.updateInput();
      }
    } else {
      // Si no se encuentra la ciudad
      this.formRecharge.controls.city.setValue("");
      if (this.inputAutocomplete) {
        this.inputAutocomplete.updateInput();
      }
    }
  }

  /**
   * Hide notification
   */
  closeMessage(): void {
    this.snackBar.dismiss();
  }

  async getCities(): Promise<any> {
    const promiseCities = await this.selectCities$.pipe(take(1)).toPromise();
    if (promiseCities.data === null) {
      this.appFacade.setLoaderShow({
        type: "GENERAL",
      });
      this.appFacade.getCities();
      this.selectCities2$.pipe(takeUntil(this.destroy$)).subscribe((cities) => {
        if (cities.data) {
          this.cities = cities.data;
        }
        this.appFacade.setLoaderHide();
      });
    }
    if (promiseCities.data !== null) {
      this.cities = promiseCities.data;
    }
  }

  inputEvent($event, inputModal: any): void {
    if (
      $event.inputType === "insertFromPaste" ||
      $event.inputType === "insertText"
    ) {
      if (
        inputModal.formControl.value.length > 0 &&
        inputModal.formControl.value[0] !== "3"
      ) {
        this.viewMessageError = true;
        this.setErrorInputGeneric();
      } else {
        this.viewMessageError = false;
      }
    }
    return;
  }

  /**
   * Set errot to Input in form
   *
   * @param control
   * @param error
   * @param input
   */
  setErrorInputGeneric(
    control: string = "phone",
    error: object = { pattern: true },
    input: string = "inputPhone",
  ): void {
    this.formRecharge.get(control).setErrors(error);
    this.formRecharge.get(control).markAsTouched();
    switch (input) {
      case "inputEmail":
        this.inputEmail.updateInput();
        break;
      case "inputIdNumber":
        this.inputIdNumber.updateInput();
        break;
      default:
        this.inputPhone.updateInput();
        break;
    }
  }

  /**
   * Input blur
   */
  inputOnBlur(): void {
    if (this.inputAutocomplete.optionSelected) {
      this.setErrorInput(false);
    } else {
      this.setErrorInput(true);
    }
  }

  /**
   * Set errot to Input in form
   *
   * @param setError boolean error
   */
  setErrorInput(setError: boolean): void {
    const cityControl = this.formRecharge.get("city");
    if (setError) {
      cityControl.setErrors({ option_invalid: true });
      cityControl.markAsTouched();
    } else {
      // Limpia el error si existe:
      if (cityControl.hasError("option_invalid")) {
        cityControl.setErrors(null);
      }
      cityControl.updateValueAndValidity();
    }
    if (this.inputAutocomplete) {
      this.inputAutocomplete.updateInput();
    }
  }

  async getAccounts(): Promise<void> {
    this.selectAccounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IAccount) => {
        this.accountType = data.data[0].accountType;
      });
  }

  /**
   * onRestInput
   */
  onRestInput(): void {
    this.formRecharge.controls.city.setValue(null);
  }

  openModal(): void {
    this.modalService.open("fp-gou-redirect-modal");
  }

  closeModal(): void {
    this.modalService.close("fp-gou-redirect-modal", false);
  }

  /**
   * get type action of modal
   *
   * @param action
   * @param action.typeAction
   * @param action.payload
   */
  modalAction(action: { typeAction: string; payload?: string }) {
    switch (action.typeAction) {
      case "accept":
        this.updateTransaction();
        break;
      case "info":
        window.open("assets/documents/dudas-sobre-GOU.pdf", "_blank");
        break;
      default:
        break;
    }
  }

  onPaste(event: Event): void {
    event.preventDefault();
  }

  get selectCities$(): Observable<ICities> {
    return this.appFacade.selectCities$;
  }

  get selectCities2$(): Observable<ICities> {
    return this.appFacade.selectCities2$;
  }

  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  loadHabeasData(): void {
    this.documentService.getHabeasData().subscribe((value) => {
      this.urlDataPolicy = value.data[0].documents[1].url;
      this.versionDataPolicy = value.data[0].version;
    });
  }
}
