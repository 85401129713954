import { createReducer, on } from "@ngrx/store";
import { loadAllDataSuccess } from "../actions/all-data.actions";
import * as userInfoActions from "../actions/user-info.actions";
import { initialUserInfoState } from "../state/user-info.state";

export const userInfoReducer = createReducer(
  initialUserInfoState,
  on(userInfoActions.userInfoLoad, (state) => ({
    ...state,
    data: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(userInfoActions.userInfoSave, (state, { data }) => ({
    ...state,
    data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(userInfoActions.userInfoError, (state, { error }) => ({
    ...state,
    data: null,
    error,
    loaded: false,
    loading: false,
  })),
  on(userInfoActions.userInfoReset, () => ({
    ...initialUserInfoState,
  })),
  on(loadAllDataSuccess, (state, { userInfo }) => ({
    ...state,
    loading: false,
    loaded: true,
    data: {
      ...userInfo,
    },
  })),
);
