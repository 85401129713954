import { Injectable } from "@angular/core";
import { IAllData } from "@app/models/all-data";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { DashboardService } from "@shared/services/dashboard.service";
import { of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import {
  loadAllData,
  loadAllDataFailure,
  loadAllDataSuccess,
} from "../actions/all-data.actions";
@Injectable()
export class AllDataEffect {
  loadAllDataEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllData),
      switchMap(() =>
        this.dashboardService.getAllData().pipe(
          map((resp: IAllData) =>
            loadAllDataSuccess({
              userInfo: resp.userData,
              accounts: resp.accountsData,
              address: resp.userData.addresses,
              logins: resp.loginsData,
              companyData: resp.companyData,
            }),
          ),
          catchError((error) => of(loadAllDataFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
