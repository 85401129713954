import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { pageWhitMenu } from "@core/constants/pages-menu.constant";
import { environment } from "@environment";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "@shared/services/authentication.service";
import { Languages } from "@store/actions/app.actions";
import { ConnectionService } from "ng-connection-service";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppFacade } from "./app.facade";
import { pageViewEvent } from "./helpers/utag.helpers";
import { ILoader, ILoaderState } from "./models/loader";
import { IUser } from "./models/user";
import { RouteTrackingService } from "./modules/account/services/route-tracking/route-tracking.service";

@Component({
  selector: "fp-root",
  styleUrls: ["./app.component.css"],
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  showMenu: boolean = false;
  inactivityTime = environment.inactivityTime;
  timeoutId: any;
  @ViewChild("internetConnection", { static: true })
  internetConnection: TemplateRef<any>;
  userInactive: Subject<void> = new Subject();
  loading: boolean = false;
  loader: ILoader = {
    title: "",
    description: "",
  };
  permissionsLoaded: any;
  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private router: Router,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    private snackBar: MatSnackBar,
    private elementRef: ElementRef,
    public permissionService: NgxPermissionsService,
    private cd: ChangeDetectorRef,
    private routeTrackingService: RouteTrackingService,
  ) {
    this.authenticationService.getUserData();
  }
  /**
   * Check Time out with Active current session
   */
  checkTimeOut(): void {
    this.timeoutId = setTimeout(() => {
      this.authenticationService
        .getCurrentAuthenticatedUser()
        .then((userCognito) => {
          if (userCognito && userCognito?.data) {
            this.userInactive.next();
          }
        })
        .catch((err) => {});
    }, this.inactivityTime);
  }

  /**
   * Init state to component
   */
  async ngOnInit(): Promise<any> {
    await this.authenticationService.getUserData();
    this.permissionsLoaded = this.permissionService.getPermissions();
    this.elementRef.nativeElement.removeAttribute("ng-version");
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
    this.userInactive.subscribe(() => {
      this.router.navigate(["/signout"]);
    });
    this.selectLoader$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.loading = value.loading;
      this.cd.detectChanges();
      if (value.loading) {
        document.body.classList.add("loader-active");
      } else {
        document.body.classList.remove("loader-active");
      }
      switch (value.type) {
        case "GENERAL":
          this.loader.title = "loader.general.title";
          this.loader.description = "loader.general.description";
          break;
        case "LOGIN":
          this.loader.title = "loader.login.title";
          this.loader.description = "loader.login.description";
          break;
      }
    });
    this.registerRouteChanges();
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * Set language
   * @param language type Languages
   */
  changeLanguage(language: Languages): void {
    this.appFacade.changeLanguaje(language);
  }
  /**
   * Change observable router's
   */
  registerRouteChanges(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      this.checkUserData()
        .then(() => {
          if (event instanceof NavigationEnd) {
            const path = event.url.split("?")[0];
            this.showMenu = !!pageWhitMenu.find((p) => p.route === event.url);
            pageViewEvent(path);
          }
        })
        .catch((err) => {});
    });
  }

  async checkUserData(): Promise<any> {
    let userCognito: IUser;
    userCognito = await this.authenticationService.getCurrentAuthenticatedUser();
    if (userCognito) {
      await this.authenticationService.getUserData();
    }
  }
  @HostListener("window:keydown")
  @HostListener("window:mousedown")
  @HostListener("window:mouseover")
  @HostListener("touchstart", ["$event"])
  @HostListener("touchend", ["$event"])
  /**
   * verify User Activity
   */
  async checkUserActivity(): Promise<any> {
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }

  @HostListener("window:keydown")
  @HostListener("click", ["$event.target"])
  async checkRefreshToken(): Promise<any> {
    let userCognito: IUser;
    userCognito = await this.authenticationService.getCurrentAuthenticatedUser();
    if (userCognito) {
      const time = await this.authenticationService.calculateTimeExpiration();
      if (time < 60000 && time > 100) {
        await this.authenticationService.refreshToken();
      }
      if (time <= 100) {
        this.router.navigate(["/signout"]);
      }
    }
  }

  /**
   * check Internet Connection to show an notification
   */
  checkInternetConnection(): void {
    this.connectionService.monitor().subscribe((isConnected) => {
      if (!isConnected) {
        this.snackBar.openFromTemplate(this.internetConnection, {
          horizontalPosition: "center",
          panelClass: "fp-snack-modify",
          verticalPosition: "top",
        });
      } else {
        this.snackBar.dismiss();
      }
    });
  }
  /**
   * get selector selectTagTermsandConditions on Facade
   */
  get selectLoader$(): Observable<ILoaderState> {
    return this.appFacade.selectLoader$;
  }

  /**
   * get selector selectLanguaje on Facade
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}
