import { createReducer, on } from "@ngrx/store";
import {
  accountSelected,
  accountSelectedReset,
} from "../actions/account-selected.actions";
import { initialAccountSelectedState } from "../state/app.state";

export const accountSelectedReducer = createReducer(
  initialAccountSelectedState,
  on(accountSelected, (state, { account }) => ({
    ...account,
  })),
  on(accountSelectedReset, (state) => ({
    ...state,
    ...initialAccountSelectedState,
  })),
);
