import { IAccount } from "@app/models/all-data";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { accountsFeatureName } from "../state/app.state";
import { LoginsRootSelector } from "./logins.selector";

export const AccountsRootSelector = createFeatureSelector<IAccount>(
  accountsFeatureName,
);

export const selectAccounts = createSelector(
  AccountsRootSelector,
  LoginsRootSelector,
  (accountsState, loginsState) => {
    if (!accountsState) {
      return null;
    }

    const newState = { ...accountsState };
    if (!accountsState.data || !loginsState?.data?.accounts) {
      newState.data = [];
      return newState;
    }

    newState.data = accountsState.data.filter((acc) =>
      loginsState.data.accounts.includes(acc.accountId.toString()),
    );
    return newState;
  },
);
