import { HttpClient, HttpHandler } from "@angular/common/http";
import { Inject, Injector } from "@angular/core";
import { Router, RouterState } from "@angular/router";
import { AESEncryptDecryptService } from "@app/modules/shared/services/aes-encrypt-decrypt.service";
import { AuthenticationService } from "@app/modules/shared/services/authentication.service";
import { Logger } from "@app/utils/logger";
import { AxiosInstance } from "axios";
import { v4 as uuidv4 } from "uuid";

/**
 * Open database
 * @param dbName string
 * @param version number
 * @returns Promise<IDBDatabase>
 */
const openDatabase = (
  dbName: string,
  version: number,
): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(dbName, version);

    openRequest.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
    };

    openRequest.onsuccess = (event) => {
      resolve((event.target as IDBOpenDBRequest).result);
    };

    openRequest.onerror = (event) => {
      reject((event.target as IDBOpenDBRequest).error);
    };
  });
};

/**
 * Get data from database
 * @param db IDBDatabase
 * @param storeName string
 * @param key any
 * @returns Promise<any>
 */
const getData = (
  db: IDBDatabase,
  storeName: string,
  key: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const objectStore = transaction.objectStore(storeName);
    const getRequest = objectStore.get(key);

    getRequest.onsuccess = (event) => {
      resolve((event.target as IDBRequest).result);
    };

    getRequest.onerror = (event) => {
      reject((event.target as IDBRequest).error);
    };
  });
};

/**
 * Add request interceptor
 * @param client AxiosInstance
 */
export const addRequestInterceptor = async (
  client: AxiosInstance,
): Promise<void> => {
  client.interceptors.request.use(
    async (request: { url; headers; [name: string]: string }) => {
      request.headers["ctype"] = "pj";
      request.headers["request-uuid"] = uuidv4() + "|" + new Date().getTime();
      request.headers["request-date"] = new Date().toDateString();
      try {
        const db = await openDatabase("FpPJDB", 2);
        const data = JSON.parse(await getData(db, "FpPJDB", "user"));
        request.headers.set(
          "Authorization",
          `Bearer ${sessionStorage.getItem("customToken") ??
            data.data.authenticationResult.idToken}`,
        );
        request.headers["X-Client-Id"] =
          data.data.authenticationResult.nickname;
      } catch (error) {
        Logger.debug("Error:", error);
      }
      return request;
    },
  );
};

/**
 * Add response interceptor
 * @param client AxiosInstance
 */
export const addResponseInterceptor = (client: AxiosInstance): void => {
  client.interceptors.response.use((response) => {
    return response;
  });
};
