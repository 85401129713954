import { IAccounts } from "@app/models/all-data";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { accountSelectedFeatureName } from "../state/app.state";

export const modulesRootSelector = createFeatureSelector<IAccounts>(
  accountSelectedFeatureName,
);

export const selectAccountSelected = createSelector(
  modulesRootSelector,
  (state: IAccounts) => state,
);
