import {
  IAccounts,
  IAddress,
  ICompanyData,
  ILoginsData,
  IUserData,
} from "@app/models/all-data";
import { createAction, props } from "@ngrx/store";

// Cargar todo
export const loadAllData = createAction("[App] Load All Data");

// Exito al cargar
export const loadAllDataSuccess = createAction(
  "[App] Load All Data Success",
  props<{
    userInfo: IUserData;
    accounts: IAccounts[];
    address: IAddress[];
    logins: ILoginsData;
    companyData: ICompanyData;
  }>(),
);

// Error
export const loadAllDataFailure = createAction(
  "[App] Load All Data Failure",
  props<{ error: any }>(),
);
