import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { Http } from "@app/modules/account/services/http-client/HttpClient";
import { environment } from "@environment";
import { from, Observable, of } from "rxjs";
import { ICreateTransactionRequest } from "../models/transaction.interface";

@Injectable({
  providedIn: "root",
})
export class TransactionsService {
  /**
   * Constructor
   * @param http hhtp Client
   */
  constructor(private http: HttpClient) {}

  getTransactionReceipt(transactionId: number | string): Observable<any> {
    return this.http.get(
      environment.api_key.payments.back_url +
        environment.endpoints.recepit.init +
        environment.endpoints.recepit.specific.transactionId +
        transactionId,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token"),
      },
    );
  }

  getTransactionReceip(transactionId: number | string) {
    const result = Http.consumeService({
      url:
        environment.api_key.payments.back_url +
        environment.endpoints.recepit.init +
        environment.endpoints.recepit.specific.transactionId +
        transactionId,
      method: "GET",
    });
    return of(result);
  }

  /**
   * Create recharge transaction
   */
  async createTransaction(payload: ICreateTransactionRequest) {
    return from(
      Http.consumeService({
        url:
          environment.api_key.services.back_url +
          environment.endpoints.transaction.init +
          environment.endpoints.transaction.specific.createTransaction,
        method: "POST",
        body: payload,
      }).then((result) => ({ ...result })),
    );
  }

  /**
   * update transaction
   * @param paymentId string
   * @param payload any
   */
  updateTransaction(paymentId: string, payload: any) {
    return from(
      Http.consumeService({
        url:
          environment.api_key.services.back_url +
          environment.endpoints.transaction.init +
          environment.endpoints.transaction.specific.updatePayment +
          "/" +
          paymentId,
        method: "PUT",
        body: payload,
      }),
    );
  }

  /**
   * validate payment
   * @param paymentId string
   */
  validatePayment(paymentId: string) {
    const payload = {
      idPaymentProvider: "GOU",
      responseData: `id=${paymentId}`,
    };
    return from(
      Http.consumeService({
        url:
          environment.api_key.services.back_url +
          environment.endpoints.transaction.init +
          environment.endpoints.transaction.specific.paymentValidation,
        method: "POST",
        body: payload,
      }),
    );
  }

  /**
   * update transaction
   * @param params any
   */
  async getTransactionsByStatus(params: {
    key?: string;
    limit: number;
    account: string;
  }) {
    let httpParams = new HttpParams()
      .set("limit", params.limit.toString())
      .set("account", params.account.toString());

    if (params.key) {
      httpParams = httpParams.set("key", params.key);
    }
    const result = await Http.consumeService({
      url:
        environment.api_key.services.back_url +
        environment.endpoints.transaction.init +
        environment.endpoints.transaction.specific.getTransactions +
        "?" +
        httpParams,
      method: "GET",
    });

    return of(result);
  }
}
