import { createReducer, on } from "@ngrx/store";
import * as accountsActions from "../actions/accounts.actions";
import { loadAllDataSuccess } from "../actions/all-data.actions";
import { initialAccountState } from "../state/app.state";

export const accountsReducer = createReducer(
  initialAccountState,
  on(accountsActions.accountsLoad, (state) => ({
    ...state,
    data: null,
    error: null,
    loaded: false,
    loading: true,
  })),
  on(accountsActions.accountsSave, (state, { data }) => ({
    ...state,
    data,
    error: null,
    loaded: true,
    loading: false,
  })),
  on(accountsActions.accountsError, (state, { error }) => ({
    ...state,
    data: null,
    error,
    loaded: false,
    loading: false,
  })),
  on(accountsActions.accountsReset, () => ({
    ...initialAccountState,
  })),
  on(loadAllDataSuccess, (state, { accounts }) => ({
    ...state,
    loading: false,
    loaded: true,
    data: accounts,
  })),
);
