import { createReducer, on } from "@ngrx/store";
import { loadAllDataSuccess } from "../actions/all-data.actions";
import { initialConpanyDataState } from "../state/company-data.state";

export const companyDataReducer = createReducer(
  initialConpanyDataState,
  on(loadAllDataSuccess, (state, { companyData }) => ({
    ...state,
    loading: false,
    loaded: true,
    data: companyData,
  })),
);
