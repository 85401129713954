import { Component } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { IAccounts } from "@app/models/all-data";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ModalService } from "../modal/service/modal.service";

export interface IItemsMenu {
  title: string;
  icon: string;
  router: string;
  permission: string;
  id: string;
}

@Component({
  selector: "fp-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent {
  itemsMenu: IItemsMenu[] = [
    {
      title: "Inicio",
      icon: "assets/images/nav-menu/home.svg",
      router: "/home",
      id: "dashboard",
      permission: "MNUDSH",
    },
    {
      title: "Vehículos",
      icon: "assets/images/nav-menu/vehicles.svg",
      router: "/vehicles/list",
      id: "vehicles-list",
      permission: "VEHCON",
    },
    {
      title: "Recargar",
      icon: "assets/images/nav-menu/recharge.svg",
      router: "/transactions/recharge",
      id: "transactions-recharge",
      permission: "CUEASI",
    },
    {
      title: "Reportes",
      icon: "assets/images/home/user-actions/reports.svg",
      router: "/reports/list",
      id: "reports-list",
      permission: "REPCON",
    },
  ];
  constructor(
    private commonsService: CommonsService,
    private modalService: ModalService,
    private permissionsService: NgxPermissionsService,
    private appFacade: AppFacade,
  ) {}

  async handleOption(itemMenu: IItemsMenu) {
    if (itemMenu.id === "transactions-recharge") {
      const hasCuesel = await this.permissionsService.hasPermission("CUESEL");

      const selectedAccount: IAccounts = await this.getAccountSelected();

      if (
        selectedAccount?.accountType !== undefined &&
        selectedAccount?.statusAccount.toLowerCase() === "activo"
      ) {
        this.commonsService.navigate(itemMenu.router);
      }

      if (selectedAccount?.statusAccount.toLowerCase() !== "activo") {
        this.modalService.open("fp-pop-up-permission-validation");
      }

      if (
        selectedAccount?.statusAccount.toLowerCase() === "activo" &&
        selectedAccount?.accountType === undefined
      ) {
        if (!hasCuesel) {
          this.modalService.open("fp-pop-up-permission-validation");
        } else {
          this.modalService.open("fp-pop-up-modality-validation");
        }
      }
    } else {
      this.commonsService.navigate(itemMenu.router);
    }
  }

  async getAccountSelected(): Promise<any> {
    const promiseAccountSelected = await this.selectAccountSelected$
      .pipe(take(1))
      .toPromise();
    return promiseAccountSelected;
  }

  get selectAccountSelected$(): Observable<IAccounts> {
    return this.appFacade.selectAccountSelected$;
  }
}
