import { Component, EventEmitter, Input, Output } from "@angular/core";
import { pageViewEvent } from "@app/helpers/utag.helpers";
import { IVehiclesInfo } from "@home/models/home";
import { ModalService } from "@theme/components/modal/service/modal.service";
import { IDeleteVehicleRequest } from "@vehicles/models/vehicle";

@Component({
  selector: "fp-delete-vehicle",
  templateUrl: "./delete-vehicle.component.html",
})
export class DeleteVehicleComponent {
  @Input() vehicleInfo: IVehiclesInfo;
  @Input() titleModal: string = "";
  @Input() time: string = "";
  @Output() itemClickActionDelete = new EventEmitter<{
    payload: IDeleteVehicleRequest;
    typeAction: string;
  }>();

  constructor(private modalService: ModalService) {}

  async actionModal($event: {
    payload: IDeleteVehicleRequest;
    typeAction: string;
  }): Promise<any> {
    const data: IDeleteVehicleRequest = {
      accountId: this.vehicleInfo.account,
      personId: this.vehicleInfo.personId,
      plate: this.vehicleInfo.vehicle.licensePlate,
      tagId: this.vehicleInfo.tagEntityConfirmation
        ? this.vehicleInfo.tagEntityConfirmation.id
        : "",
    };
    $event.payload = data;
    this.itemClickActionDelete.emit($event);
  }
  /**
   * Close modal
   * @param id string id elemnt Html
   */
  closeModal(id: string): void {
    this.modalService.close(id, false);
    pageViewEvent(`/vehicles/list`);
  }
}
