import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  UserDataPolicyModuleState,
  UserDataPolicyFeatureName,
} from "../state/data-policy.state";

export const UserDataPolicyRootSelector = createFeatureSelector<
  UserDataPolicyModuleState
>(UserDataPolicyFeatureName);

export const selectUserDataPolicy = createSelector(
  UserDataPolicyRootSelector,
  (state: UserDataPolicyModuleState) => state,
);
