import { HttpClient } from "@angular/common/http";
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AppFacade } from "@app/app.facade";
import { pageEvent, pageViewEvent } from "@app/helpers/utag.helpers";
import {
  IAccount,
  IAccounts,
  ICompany,
  ICompanyData,
  IUserData,
  IUserInfo,
} from "@app/models/all-data";
import { tagActivationPagesConst } from "@core/constants/pages.constant";
import { KeysPipe } from "@core/pipe/keys.pipe";
import { TranslateService } from "@ngx-translate/core";
import { ITagActivation } from "@shared/models/tag-activation";
import { AuthenticationService } from "@shared/services/authentication.service";
import { CommonsService } from "@shared/services/commons.service";
import { ExcelService } from "@shared/services/excel.service";
import { NotifyService } from "@shared/services/notify.service";
import { IMinimumAccount } from "@shared-financial-data/models/financial-data";
import { SharedFinancialDataFacade } from "@shared-financial-data/store/facade/shared-financial-data.facade";
import { TagFacade } from "@tag/store/facade/tag.facade";
import { initialStateTagNumber } from "@tag/store/state/tag.state";
import { ModalService } from "@theme/components/modal/service/modal.service";
import moment from "moment";
import { OwlOptions } from "ngx-owl-carousel-o";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject, forkJoin, throwError } from "rxjs";
import { catchError, take, takeUntil, timeout } from "rxjs/operators";
import { ITagInfo, IVehiclesInfo } from "../../../home/models/home";
import {
  IDeleteVehicleRequest,
  IValidateVehicleRequest,
  IVehicle,
  IVehiclesCategories,
  IVehiclesCategoriesResponse,
  IVehiclesList,
  IVehiclesListResponse,
  IVehiclesRequest,
  LastEvaluatedKey,
  VehicleValidationState,
  VehicleTAGStatus,
} from "../../models/vehicle";
import { VehicleNavigationService } from "../../services/vehicle-navigation.service";
import { VehiclesService } from "../../services/vehicle.service";
import { VehiclesFacade } from "../../store/facade/vehicles.facade";

@Component({
  selector: "fp-vehicles",
  templateUrl: "./list-vehicle.component.html",
  styleUrls: ["./list-vehicle.component.scss"],
})
export class ListVehicleComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpVehicles = "fp-vehicles";
  accounts: IAccounts[];
  formAccount: UntypedFormGroup;
  expand: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  error: boolean = false;
  pages: number[] = [];
  pagesItems: number;
  filterLimit: number = 10;
  username: string;
  loading: boolean = true;
  vehiclesState: IVehiclesList;
  accountSelect: IAccounts;
  vehicles: IVehiclesListResponse[] = [];
  vehiclesPaginator: any[] = [];
  ipageActive: number = 1;
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    startPosition: 0,
    stagePadding: 20,
    margin: 10,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
    nav: false,
    center: false,
  };
  itemsVehiclesChageStatus: IVehiclesInfo[] = [];
  activateSelection: boolean = true;
  inactivateSelection: boolean = true;
  filters = {
    licensePlate: false,
    numberTag: false,
    infoAditional: false,
    state: false,
    acction: false,
  };
  titleModal: string = "modal.title";
  messageError: string;
  hideClose: boolean;
  @ViewChild("tempError", { static: true })
  tempMessageError: TemplateRef<any>;
  time: string = "las próximas 6 horas";
  minAmountPos: string;
  minAmountPre: string;
  typeActionSelected: boolean;
  searchValues: string[] = [];
  totalItemsforAccount: number;
  vehicleInfo: IVehiclesInfo;
  userInfo: IUserData;
  companyData: ICompanyData;

  lastEvaluatedKey: LastEvaluatedKey;
  concatData: boolean = false;

  formVehicles: UntypedFormGroup;

  chooseCreationType: boolean = false;

  modalities = [
    {
      value: "1",
      recommended: true,
      title: "Crear vehículos",
      subtitle: "Para max. 20 vehículos",
      message: "Aquí podrá incluir uno a uno los vehículos que desee crear.",
    },
  ];

  formSelectModality: UntypedFormGroup;
  manual: boolean;

  createVehicles: boolean;

  vehicleTypes: IVehiclesCategoriesResponse[];
  // Estado inicial para cada vehículo
  validationStates: {
    state: VehicleValidationState;
    errorCode?: string;
    errorMessage?: string;
  }[] = [];

  gouLink: string = "";
  showRequestTags = false;

  constructor(
    private appFacade: AppFacade,
    private authenticationService: AuthenticationService,
    private commonsService: CommonsService,
    public translate: TranslateService,
    private vehiclesFacade: VehiclesFacade,
    private keysPipe: KeysPipe,
    private modalService: ModalService,
    private notifyService: NotifyService,
    private sharedFinancialFacade: SharedFinancialDataFacade,
    private tagFacade: TagFacade,
    public permissionService: NgxPermissionsService,
    private excelService: ExcelService,
    private vehiclesService: VehiclesService,
    private vhNavigation: VehicleNavigationService,
    private http: HttpClient,
  ) {}
  async ngOnInit(): Promise<void> {
    this.vehiclesFacade.setVehicleInformation({
      licensePlate: null,
      categoryValue: null,
      accountId: null,
    });
    this.tagFacade.setTagNumber(initialStateTagNumber);
    this.tagFacade.setTagActivationPages([tagActivationPagesConst[0]]);
    this.initForm();
    await this.getUserInfo();
    await this.getAccounts();
    await this.setSelectedAccount();
    await this.loadMinimumAmountPrepaid();
    await this.loadMinimumAmountPostpaid();
    await this.getCompanyData();
    this.username = await this.authenticationService.getUsername();
    this.vehiclesFacade.vehicleListReset();
    this.selectVehiclesList();
    this.getVehicleCategories();
    this.formSelectModality = new UntypedFormGroup({
      paymentType: new UntypedFormControl("1", [Validators.required]),
    });

    this.http
      .get("assets/i18n/vehicles/vehicles/es.json")
      .subscribe((data: any) => {
        this.gouLink = data.popup.link;
      });
  }

  navigateTo(route: string) {
    this.commonsService.navigate(route);
  }

  selectAll(flag: boolean = false): void {
    if (this.vehiclesPaginator[this.ipageActive - 1]) {
      const paginator: any[] = this.vehiclesPaginator[this.ipageActive - 1];
      Array.from(paginator).forEach((vehicle) => {
        this.formAccount.controls[vehicle.vehicle.licensePlate].setValue(
          this.formAccount.controls.selectAll.value,
        );
        if (this.formAccount.controls.selectAll.value) {
          this.toggleSelection(
            {
              checked: this.formAccount.controls.selectAll.value,
              vehicle,
            },
            flag,
          );
        } else {
          this.resetSelection();
        }
      });
    }
  }

  selectVehiclesList(): void {
    this.vehiclesFacade.getVehiclesList(this.username, this.lastEvaluatedKey);
    this.selectVehiclesList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IVehiclesList) => {
        this.vehiclesState = action;
        this.loading = action.loading;
        if (action.data) {
          this.lastEvaluatedKey = action.data.lastEvaluatedKey;
          this.vehicles = this.concatData
            ? this.vehicles.concat(action.data.data)
            : action.data.data;
          this.vehicles.forEach((vehicle) => {
            this.formAccount.addControl(
              `${vehicle.vehicle.licensePlate}`,
              new UntypedFormControl(false),
            );
          });
          this.generatorPages();
        }
      });
  }

  async getVehicleCategories(): Promise<any> {
    const promisevehiclesCategories = await this.selectVehiclesCategories$
      .pipe(take(1))
      .toPromise();
    if (!promisevehiclesCategories || promisevehiclesCategories.data === null) {
      this.vehiclesFacade.getVehiclesCategories();
      this.selectVehiclesCategories$
        .pipe(take(2))
        .subscribe((action: IVehiclesCategories) => {
          if (action && action.data !== null) {
            this.vehicleTypes = action.data;
          }
        });
    }
    if (promisevehiclesCategories && promisevehiclesCategories.data !== null) {
      this.vehicleTypes = promisevehiclesCategories.data;
    }
  }

  async getUserInfo(): Promise<any> {
    const userInfoPromise = await this.selectUserInfo$
      .pipe(take(1))
      .toPromise();
    this.userInfo = userInfoPromise.data;
  }

  order(key: string = "licensePlate"): void {
    this.changeFilter(!this.filters[key] ? key : null);
    this.vehicles.sort((a, b) =>
      a.vehicle[key] > b.vehicle[key]
        ? this.filters[key]
          ? -1
          : 1
        : this.filters[key]
        ? 1
        : -1,
    );
    this.generatorPages(this.ipageActive);
  }

  orderByNumberTag(): void {
    this.changeFilter(!this.filters.numberTag ? "numberTag" : null);
    const vehiclesTag = this.vehicles.filter((p) => p.tagEntityConfirmation);
    const vehiclesNoTag = this.vehicles.filter((p) => !p.tagEntityConfirmation);
    vehiclesTag.sort((a, b) =>
      a.tagEntityConfirmation.idFacial > b.tagEntityConfirmation.idFacial
        ? 1
        : -1,
    );
    !this.filters.numberTag
      ? (this.vehicles = [...vehiclesTag, ...vehiclesNoTag])
      : (this.vehicles = [...vehiclesNoTag, ...vehiclesTag]);

    this.generatorPages(this.ipageActive);
  }

  orderByStateOrAction(flag: string = "state"): void {
    this.changeFilter(
      flag === "state"
        ? !this.filters.state
          ? "state"
          : null
        : !this.filters.acction
        ? "acction"
        : null,
    );
    const vehiclesTagActive = this.vehicles.filter(
      (p) => p.tagEntityConfirmation?.currentStatus === 1,
    );
    const vehiclesTagInactive = this.vehicles.filter(
      (p) =>
        p.tagEntityConfirmation?.currentStatus === 0 ||
        p.tagEntityConfirmation?.currentStatus === 2,
    );
    const vehiclesNoTag = this.vehicles.filter((p) => !p.tagEntityConfirmation);
    if (flag === "state") {
      this.vehicles = !this.filters.state
        ? [...vehiclesTagActive, ...vehiclesTagInactive, ...vehiclesNoTag]
        : [...vehiclesNoTag, ...vehiclesTagInactive, ...vehiclesTagActive];
    } else {
      this.vehicles = !this.filters.acction
        ? [...vehiclesTagInactive, ...vehiclesTagActive, ...vehiclesNoTag]
        : [...vehiclesNoTag, ...vehiclesTagActive, ...vehiclesTagInactive];
    }
    this.generatorPages(this.ipageActive);
  }

  changeFilterLimit($event): void {
    this.filterLimit = $event;
    this.generatorPages();
  }
  /**
   * Destroy subscribes
   */
  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.unsubscribe();
  }

  generatorPages(flagPage: number = 1, flagFilter: boolean = false): void {
    let vehiclesFilter =
      this.vehicles.filter(
        (p) => p.vehicle.account === this.accountSelect.accountId.toString(),
      ) ?? [];
    this.showRequestTags = vehiclesFilter.some(
      (v) => v.tagEntityConfirmation?.currentStatus === VehicleTAGStatus.ACTIVO,
    );

    this.totalItemsforAccount = JSON.parse(
      JSON.stringify(vehiclesFilter.length),
    );
    if (flagFilter) {
      this.searchValues.forEach((value: string) => {
        vehiclesFilter = vehiclesFilter.filter(
          (p) =>
            p.vehicle.licensePlate
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            p.tagEntityConfirmation?.idFacial
              .toLocaleLowerCase()
              .includes(value.toLocaleLowerCase()) ||
            ("sin tag".includes(value.toLocaleLowerCase()) &&
              !p.tagEntityConfirmation) ||
            ("inactivo".includes(value.toLocaleLowerCase()) &&
              value.toLocaleLowerCase() !== "activo" &&
              (p.tagEntityConfirmation?.currentStatus.toString() === "0" ||
                p.tagEntityConfirmation?.currentStatus.toString() === "2")) ||
            ("activo".includes(value.toLocaleLowerCase()) &&
              p.tagEntityConfirmation?.currentStatus.toString() === "1"),
        );
      });
    }
    this.vehiclesPaginator = [];
    this.pagesItems = Math.ceil(vehiclesFilter.length / this.filterLimit);
    for (let index = 0; index < this.pagesItems; index++) {
      const position = vehiclesFilter.slice(
        index * this.filterLimit,
        index * this.filterLimit + this.filterLimit,
      );
      this.vehiclesPaginator.push(position);
    }

    if (this.pagesItems >= 7) {
      this.pages = [3, 4, 5];
    } else {
      this.pages = [];
      for (let index = 2; index < this.pagesItems; index++) {
        this.pages.push(index);
      }
    }
    this.changeIndexPage(flagPage);
  }

  /**
   * init form for accounts
   */
  initForm(): void {
    this.formAccount = new UntypedFormGroup({
      inputSearch: new UntypedFormControl("", [
        Validators.pattern("^[a-zA-Z0-9]{4,10}$"),
      ]),
      account: new UntypedFormControl("", [Validators.required]),
      selectAll: new UntypedFormControl(false, [Validators.requiredTrue]),
      check: new UntypedFormControl(true, [Validators.requiredTrue]),
    });

    this.formVehicles = new UntypedFormGroup({
      vehicles: new UntypedFormArray([]), // Inicializa un FormArray vacío
    });
  }
  /**
   * Chose account selected
   */
  chooseAccount(data: { account: IAccounts; flag: boolean }): void {
    this.formAccount.controls.account.setValue(data.account?.accountId);
    this.appFacade.setAccountSelected(data.account);
    this.accountSelect = { ...data.account };
    if (!data.flag) {
      this.searchValues = [];
      this.changeFilterLimit(10);
    }
    this.chooseCreationType = false;
    this.manual = false;

    if (this.vehiclesFormArray.length == 1) {
      this.vehiclesFormArray.clear();
      this.validationStates.pop();
    }
  }

  /**
   * get accounts info
   */
  async getAccounts(): Promise<any> {
    const promiseAccounts = await this.selectAccounts$
      .pipe(take(1))
      .toPromise();
    if (promiseAccounts.data === null) {
      this.appFacade.getAccounts(
        await this.authenticationService.getUsername(),
      );
      this.selectAccounts2$
        .pipe(takeUntil(this.destroy$))
        .subscribe((action: IAccount) => {
          if (action && action.data !== null && action.data.length > 0) {
            this.accounts = action.data.filter(
              (x) => x.accountType && x.statusAccount === "Activo",
            );
          } else if (action && action.error) {
            this.commonsService.navigate("/error");
          }
        });
    }
    if (promiseAccounts.data !== null) {
      this.accounts = promiseAccounts.data.filter(
        (x) => x.accountType && x.statusAccount === "Activo",
      );
    }
    if (this.accounts && this.accounts.length > 0) {
      const account = this.accounts[0];
      this.formAccount.controls.account.setValue(account);
    }
  }

  async setSelectedAccount(): Promise<any> {
    const promiseAccountSelected = await this.selectAccountSelected$
      .pipe(take(1))
      .toPromise();
    if (promiseAccountSelected) {
      const data = {
        account: promiseAccountSelected,
        flag: true,
      };
      this.chooseAccount(data);
    } else if (this.accounts?.length > 0) {
      const data = {
        account: this.accounts[0],
        flag: true,
      };
      this.chooseAccount(data);
    }
  }

  getVehicles(): void {
    this.vehiclesFacade.getVehiclesList(this.username);
  }

  updateVehicle($event: IVehiclesInfo): void {
    const updateVehicle: IVehicle = {
      plate: $event.vehicle.licensePlate,
      category: $event.vehicle.categoryValue,
      additionalInfo: $event.vehicle.additionalInformation,
      noTag: $event.tagEntityConfirmation
        ? $event.tagEntityConfirmation.idFacial
        : null,
      personId: $event.personId,
      accountId: $event.account,
    };
    this.vehiclesFacade.setVehicleToUpdate(updateVehicle);
    this.vhNavigation.next(3);
  }

  deleteVehicleShowModal($event: IVehiclesInfo): void {
    this.vehicleInfo = $event;
    this.modalService.open("fp-delete-vehicle");
  }

  async deleteVehicleClose($event: {
    payload: IDeleteVehicleRequest;
    typeAction: string;
  }) {
    this.closeModal("fp-delete-vehicle");
    if ($event.typeAction === "accept") {
      try {
        this.appFacade.setLoaderShow({
          type: "GENERAL",
        });
        await this.vehiclesService.deleteVehicle($event.payload).toPromise();
        this.selectVehiclesList();
      } catch (error) {
        this.messageError =
          "No fue posible eliminar el vehiculo, inténtelo más tarde";
        this.showError();
      } finally {
        this.appFacade.setLoaderHide();
      }
    }
  }

  /**
   * Change Status on tag
   * @param tagId tag Id
   */
  async changeStatusTag(tagId: ITagInfo): Promise<any> {
    this.error = false;
    let status: "ACTIVE" | "INACTIVE";
    if (tagId.currentStatus !== 1) {
      status = "ACTIVE";
      this.messageError =
        "No fue posible activarlo en este momento, inténtelo más tarde";
    } else {
      status = "INACTIVE";
      this.messageError =
        "No fue posible inactivarlo en este momento, inténtelo más tarde";
    }
    try {
      const infoActivation: ITagActivation = {
        personId: tagId.idClient,
        tagId: tagId.id,
        status,
      };
      const respActivateTag = await this.commonsService.callTagActivationService(
        infoActivation,
      );
      if (!respActivateTag.error) {
        this.titleModal =
          tagId.currentStatus !== 1
            ? "Activación en proceso"
            : "Inactivación en proceso";
        this.setMinAmountInfo(this.accountSelect.accountType);
        this.openModal("fp-activation-progress");
        this.getVehicles();
      } else {
        this.error = true;
        this.showError();
      }
    } catch (error) {
      this.error = true;
      this.showError();
      pageEvent(
        error.error?.data?.message,
        "/vehicle/list",
        tagId.currentStatus !== 1
          ? "No-fue-posible-activar-tag"
          : "No-fue-posible-inactivar-tag",
      );
    }
  }

  /**
   * show Error
   */
  showError(): void {
    this.notifyService.showNotify(this.tempMessageError);
  }

  /**
   * Close Message
   */
  closeAlertActive(): void {
    this.notifyService.closeNotify();
  }

  /**
   * Open modal
   * @param id string id element Html
   */
  openModal(id: string): void {
    this.closeAlertActive();
    this.modalService.open(id);
    if (id == "fp-gou-modal")
      pageEvent("btn-modal-gou", "/vehicle/list", "Solicita y compra TAGs");
    pageViewEvent(`/vehicles/list/${id}`);
  }

  crear() {
    this.chooseCreationType = false;
  }

  navigateGou(): void {
    pageEvent("btn-fp-gou-modal", "/vehicle/list", "Entendido");
    this.modalService.close("fp-gou-modal", true);
    window.open(this.gouLink, "_blank");
  }

  /**
   * Open modal
   * @param id string id element Html
   */
  closeModal(id: string): void {
    this.modalService.close(id, false);
    pageViewEvent(`/vehicles/list`);
  }

  openModalchangeSelection(active: boolean): void {
    this.typeActionSelected = active;
    this.openModal("fp-change-selection-status");
  }

  changeSelection($event): void {
    // eslint-disable-next-line no-console
    console.log("changeSelection", $event);
  }

  verificateSelecttion(): void {
    if (
      this.itemsVehiclesChageStatus?.length !==
      this.vehiclesPaginator[this.ipageActive - 1]?.length
    ) {
      this.formAccount.controls.selectAll.setValue(false);
    }
  }

  toggleSelection(
    $event: { checked: boolean; vehicle: IVehiclesInfo },
    flag: boolean = true,
  ): void {
    const index = this.itemsVehiclesChageStatus.findIndex(
      (p) => p === $event.vehicle,
    );
    if (index === -1) {
      this.itemsVehiclesChageStatus.push($event.vehicle);
    } else {
      if (flag) {
        this.itemsVehiclesChageStatus.splice(index, 1);
      }
    }
    let inactive = [];
    inactive = this.itemsVehiclesChageStatus.filter(
      (p) =>
        p.tagEntityConfirmation && p.tagEntityConfirmation.currentStatus === 1,
    );
    let active = [];
    active = this.itemsVehiclesChageStatus.filter(
      (p) =>
        p.tagEntityConfirmation && p.tagEntityConfirmation.currentStatus !== 1,
    );
    this.activateSelection =
      active.length === this.itemsVehiclesChageStatus.length &&
      this.itemsVehiclesChageStatus?.length !== 0
        ? false
        : true;

    this.inactivateSelection =
      inactive.length === this.itemsVehiclesChageStatus.length &&
      this.itemsVehiclesChageStatus?.length !== 0
        ? false
        : true;
    if (flag) {
      this.verificateSelecttion();
    }
  }

  prev(flag: boolean = false) {
    if (this.ipageActive > 1) {
      if (!flag) {
        this.ipageActive -= 1;
      }
      if (this.pagesItems >= 7) {
        if (this.ipageActive > 3 && this.ipageActive <= this.pagesItems - 4) {
          this.pages = this.pages.map((p) => p - 1);
        }
      }
    }
  }

  next(flag: boolean = false): void {
    if (this.ipageActive < this.pagesItems) {
      if (!flag) {
        this.ipageActive += 1;
      }
      if (this.pagesItems >= 7) {
        if (this.ipageActive >= 5 && this.ipageActive < this.pagesItems - 2) {
          this.pages = this.pages.map((p) => p + 1);
        }
      }
    }
  }

  loadMore() {
    this.concatData = true;
    this.vehiclesFacade.getVehiclesList(this.username, this.lastEvaluatedKey);
  }

  resetSelection(): void {
    this.itemsVehiclesChageStatus = [];
    this.formAccount.controls.selectAll.setValue(false);
    if (this.vehiclesPaginator[this.ipageActive - 1]) {
      const paginator: any[] = this.vehiclesPaginator[this.ipageActive - 1];
      Array.from(paginator).forEach((vehicle) => {
        this.formAccount.controls[vehicle.vehicle.licensePlate].setValue(
          this.formAccount.controls.selectAll.value,
        );
      });
    }
  }

  changeIndexPage(ipage: number): void {
    this.resetSelection();
    if (this.ipageActive < ipage) {
      this.ipageActive = ipage;
      if (this.ipageActive === this.pagesItems && this.pagesItems >= 7) {
        this.pages = [
          this.pagesItems - 4,
          this.pagesItems - 3,
          this.pagesItems - 2,
        ];
      } else {
        this.next(true);
      }
    } else if (this.ipageActive > ipage) {
      this.ipageActive = ipage;
      if (this.ipageActive === 1 && this.pagesItems >= 7) {
        this.pages = [3, 4, 5];
      } else {
        this.prev(true);
      }
    }
  }

  changeFilter(key?: string): void {
    this.keysPipe.transform(this.filters).forEach((k) => {
      this.filters[k] = false;
    });
    if (key) {
      this.filters[key] = true;
    }
  }

  loadAmountValues(serviceInfo: any): string {
    return `la${serviceInfo.tiempoActualizacionPeajes > 1 ? "s" : ""} proxima${
      serviceInfo.tiempoActualizacionPeajes > 1 ? "s" : ""
    }${
      serviceInfo.tiempoActualizacionPeajes > 1
        ? " " + serviceInfo.tiempoActualizacionPeajes
        : ""
    } hora${serviceInfo.tiempoActualizacionPeajes > 1 ? "s" : ""}`;
  }

  setMinAmountInfo(type: string = null): void {
    this.time = type === "POS" ? this.minAmountPos : this.minAmountPre;
  }

  search(): void {
    const value = this.formAccount.controls.inputSearch.value;
    if (value) {
      if (this.searchValues.length === 0) {
        this.searchValues.push(value);
      } else {
        const index = this.searchValues.findIndex((p) => p === value);
        if (index === -1) {
          this.searchValues.push(value);
        }
      }
      this.formAccount.controls.inputSearch.reset();
      this.generatorPages(1, true);
    }
  }

  deleteItem(indexItem: number): void {
    this.searchValues.splice(indexItem, 1);
    this.generatorPages(1, true);
  }

  async downloadVehiclesExcelReport(): Promise<any> {
    const dataExport: any = [];
    this.vehicles
      .filter(
        (p) => p.vehicle.account === this.accountSelect.accountId.toString(),
      )
      .forEach((data) => {
        dataExport.push({
          Placa: data.vehicle.licensePlate,
          Categoría: data.vehicle.shortDescriptionCategory,
          Estado: data?.tagEntityConfirmation
            ? data.tagEntityConfirmation.currentStatus === 1
              ? "Activo"
              : "Inactivo"
            : "Sing Tag",
          ["Información adicional"]: data.vehicle.additionalInformation,
          ["Numero TAG"]: data?.tagEntityConfirmation
            ? data.tagEntityConfirmation.idFacial
            : "",
          ["Fecha de creación"]: moment(
            new Date(data.vehicle.createdAt),
          ).format("DD/MM/YYYY"),
          ["Fecha de activación"]: data.tagEntityConfirmation
            ? moment(
                new Date(data.tagEntityConfirmation.activationDate),
              ).format("DD/MM/YYYY")
            : "",
        });
      });
    this.excelService.exportAsExcelFile(
      dataExport,
      `vehiculos_cuenta_${this.accountSelect.accountId}`,
      [
        [
          "Cliente",
          this.userInfo.companyName,
          "",
          "",
          "",
          "Fecha",
          moment().format("DD/MM/YYYY"),
        ],
        [
          "Identificación",
          this.userInfo.documentNumber,
          "",
          "",
          "",
          "Cuenta ",
          this.accountSelect.accountId,
        ],
      ],
      { ref: "A5:G5" },
      [
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 50 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ],
      "Vehiculos",
    );
  }

  chooseModality(method: string): void {
    this.formSelectModality.controls.paymentType.setValue(method);
  }

  async selectModality(): Promise<any> {
    if (this.formSelectModality.controls.paymentType.value === "1") {
      this.manual = true;

      this.addVehicle();
    }
  }

  addVehicle(): void {
    const vehicleFormGroup = new UntypedFormGroup({
      plate: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9]{4,10}$"),
      ]),
      category: new UntypedFormControl("", Validators.required),
      additionalInfo: new UntypedFormControl(""),
      noTag: new UntypedFormControl(""),
      personId: new UntypedFormControl(this.userInfo.id),
      accountId: new UntypedFormControl(this.accountSelect.accountId),
    });

    this.vehiclesFormArray.push(vehicleFormGroup);
    this.validationStates.push({ state: VehicleValidationState.Idle });
  }

  deleteVehicle(index: number): void {
    const vehiclesArray = this.formVehicles.get("vehicles") as UntypedFormArray;
    if (index >= 0 && index < vehiclesArray.length) {
      vehiclesArray.removeAt(index);
      this.validationStates.splice(index, 1);
    }
  }

  validateVehicle(vehicle: IVehicle, index: number) {
    this.validationStates[index] = { state: VehicleValidationState.Validating };

    const request: IValidateVehicleRequest = {
      personId: vehicle.personId,
      accountId: vehicle.accountId,
      licensePlate: vehicle.plate,
      category: vehicle.category,
      ...(vehicle.additionalInfo && {
        additionalInformation: vehicle.additionalInfo,
      }),
      ...(vehicle.noTag && { idFacial: vehicle.noTag }),
    };

    this.vehiclesService
      .validateVehicle(request)
      .pipe(
        timeout(20000), // Lanza un error si no se recibe respuesta en 20 segundos
        catchError((error) => {
          // Personaliza la respuesta de error dependiendo del tipo
          if (error.name === "TimeoutError") {
            return throwError({
              timeoutError: true,
              message:
                "El proceso ha tardado demasiado tiempo y se ha detenido.",
            });
          }
          return throwError(error);
        }),
      )
      .subscribe({
        next: (res) => {
          if (res.code === "102580") {
            this.validationStates[index] = {
              state: VehicleValidationState.Verified,
            };
          } else {
            this.validationStates[index] = {
              state: VehicleValidationState.Error,
              errorCode: res.errorCode,
              errorMessage: res.message,
            };
          }
        },
        error: (error) => {
          if (error.timeoutError) {
            this.validationStates[index] = {
              state: VehicleValidationState.Retry, // Considera cambiar el estado si es apropiado
              errorMessage: error.message,
            };
          } else {
            if (error.error.errorCode === undefined) {
              this.validationStates[index] = {
                state: VehicleValidationState.Retry, // Considera cambiar el estado si es apropiado
                errorMessage: error.message,
              };
              return;
            }
            this.validationStates[index] = {
              state: VehicleValidationState.Error,
              errorCode: error.error.errorCode,
              errorMessage: error.error.message,
            };
          }
        },
      });
  }

  editVehicle(index: number) {
    this.validationStates[index] = { state: VehicleValidationState.Idle };
  }

  VehiclesValid(): boolean {
    return !this.validationStates.every((state) => state.state === "VERIFIED");
  }

  CreateVehicles() {
    const currentDate = new Date();
    const vehicles = this.vehiclesFormArray;

    const hasEmptyNoTag = vehicles.controls.some(
      (control) => !control.get("noTag").value,
    );

    if (hasEmptyNoTag) {
      this.vehiclesFacade.setvehiclesToCreate(vehicles.value);
      this.vhNavigation.next(1);
      return;
    }

    this.appFacade.setLoaderShow({
      type: "GENERAL",
    });

    // Crear un array de Observables
    const createVehicleObservables = vehicles.value.map((vehicle: IVehicle) => {
      const request: IVehiclesRequest = {
        personId: vehicle.personId,
        accountId: vehicle.accountId,
        licensePlate: vehicle.plate,
        category: vehicle.category,
        ...(vehicle.additionalInfo && {
          additionalInformation: vehicle.additionalInfo,
        }),
        idFacial: vehicle.noTag,
        services: this.companyData.services,
        date: currentDate,
      };

      return this.vehiclesService.createVehicle(request);
    });

    forkJoin(createVehicleObservables).subscribe({
      next: (responses) => {
        this.appFacade.setLoaderHide();
        this.vhNavigation.next(2);
      },
      error: (error) => {},
    });
  }

  async loadMinimumAmountPrepaid(): Promise<any> {
    const promiseMinPrepaid = await this.selectMinimumAmountPrepaid$
      .pipe(take(1))
      .toPromise();
    if (promiseMinPrepaid.data === null) {
      this.sharedFinancialFacade.getMinimumAmountPrepaid({
        modality: "PREPAGO",
        personType: "PERSONA_JURIDICA",
      });
      this.selectMinimumAmountPrepaid2$.pipe(take(2)).subscribe((value) => {
        if (value && value.data !== null) {
          const serviceInfo = value.data.filter(
            (x) => x.convenio === "4023",
          )[0];
          this.minAmountPre = serviceInfo
            ? this.loadAmountValues(serviceInfo)
            : "las próximas 6 horas";
        }
        if (value.error !== null) {
          this.minAmountPre = "las próximas 6 horas";
        }
      });
    } else {
      const serviceInfo = promiseMinPrepaid.data.filter(
        (x) => x.convenio === "4023",
      )[0];
      this.minAmountPre = serviceInfo
        ? this.loadAmountValues(serviceInfo)
        : "las próximas 6 horas";
    }
  }

  async loadMinimumAmountPostpaid(): Promise<any> {
    const promiseMinPospaid = await this.selectMinimumAmountPospaid$
      .pipe(take(1))
      .toPromise();
    if (promiseMinPospaid.data === null) {
      this.sharedFinancialFacade.getMinimumAmountPospaid({
        modality: "POSTPAGO",
        personType: "PERSONA_JURIDICA",
      });
      this.selectMinimumAmountPospaid2$.pipe(take(2)).subscribe((value) => {
        if (value && value.data !== null) {
          const serviceInfo = value.data[0];
          this.minAmountPos = serviceInfo
            ? this.loadAmountValues(serviceInfo)
            : "las próximas 6 horas";
        }
        if (value.error !== null) {
          this.minAmountPos = "las próximas 6 horas";
        }
      });
    } else {
      const serviceInfo = promiseMinPospaid.data[0];
      this.minAmountPos = serviceInfo
        ? this.loadAmountValues(serviceInfo)
        : "las próximas 6 horas";
    }
  }

  async getCompanyData(): Promise<any> {
    const companyPromise = await this.selectCompanyData$
      .pipe(take(1))
      .toPromise();
    this.companyData = companyPromise.data;
  }

  get vehiclesFormArray(): UntypedFormArray {
    return this.formVehicles.get("vehicles") as UntypedFormArray;
  }

  /**
   * get accounts from Selector
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }
  /**
   * get accounts from Selector
   */
  get selectAccounts2$(): Observable<IAccount> {
    return this.appFacade.selectAccounts2$;
  }
  /**
   * get vehicles from Selector
   */
  get selectVehiclesList$(): Observable<IVehiclesList> {
    return this.vehiclesFacade.selectVehiclesList$;
  }
  /**
   * get selectedAccount from selector
   */
  get selectAccountSelected$(): Observable<IAccounts> {
    return this.appFacade.selectAccountSelected$;
  }

  get selectUserInfo$(): Observable<IUserInfo> {
    return this.appFacade.selectUserInfo$;
  }

  get selectCompanyData$(): Observable<ICompany> {
    return this.appFacade.selectCompanyData$;
  }

  /**
   * get vehicle categories from Selector
   */
  get selectVehiclesCategories$(): Observable<IVehiclesCategories> {
    return this.vehiclesFacade.selectVehiclesCategories$;
  }

  /**
   * get minimum amount pospaid from Selector
   */
  get selectMinimumAmountPospaid$(): Observable<IMinimumAccount> {
    return this.sharedFinancialFacade.selectMinimumAmountPospaid$;
  }
  /**
   * get minimum amount pospaid from Selector
   */
  get selectMinimumAmountPospaid2$(): Observable<IMinimumAccount> {
    return this.sharedFinancialFacade.selectMinimumAmountPospaid2$;
  }
  /**
   * get minimum amount prepaid from Selector
   */
  get selectMinimumAmountPrepaid$(): Observable<IMinimumAccount> {
    return this.sharedFinancialFacade.selectMinimumAmountPrepaid$;
  }
  /**
   * get minimum amount prepaid from Selector
   */
  get selectMinimumAmountPrepaid2$(): Observable<IMinimumAccount> {
    return this.sharedFinancialFacade.selectMinimumAmountPrepaid2$;
  }
}
