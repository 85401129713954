<fp-card-body [template]="'dashboardTemplate'" exit="true" headerType="secondary" [showfooterBallsRight]="false"
    [actionType]="'logout'">
    <ng-template #tplRef>
        <div class="conten-one">
            <fp-info-user  [userInfo]="userInfo" [loginData]="loginData">
            </fp-info-user>
            <fp-accounts></fp-accounts>
        </div>
    </ng-template>
    <ng-template #tplRefTwo>
        <div class="conten-two">
            <div class="container-content-two">
                <fp-documents-carousel></fp-documents-carousel>
                <fp-detail-account [clientInfo]="userInfo"></fp-detail-account>
            </div>
        </div>
    </ng-template>
</fp-card-body>
