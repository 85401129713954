import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { IAllData } from "@app/models/all-data";
import { IAddress } from "@app/modules/vehicles/models/address";
import { environment } from "@environment";
import {
  IMovementsDashboardRequest,
  IResponseServices,
} from "@home/models/home";
import { Observable } from "rxjs";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient) {}
  /**
   * getAccounts
   * @param clientId clientId
   * @returns Observable o Promise with data
   */
  getAccounts(nickname: string): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.accounts.init +
        `?clientId=${nickname.split("-")[0]}&userId=${nickname.split("-")[1]}`,
      {
        params: new CustomHttpParams("api-pj-public-token"),
      },
    );
  }

  getLowBalance(nickname: string): Observable<any> {
    return this.http.post(
      environment.api_key.private.back_url +
        environment.endpoints.alliesAccounts.init +
        environment.endpoints.alliesAccounts.specific.getBalanceAccount,
      {
        personId: nickname.split("-")[0],
      },
      {
        params: new CustomHttpParams("api-pn-private-apiKey-token"),
      },
    );
  }

  getCustomerInfo(): Observable<any> {
    return this.http.get(
      environment.api_key.publicPJ.back_url +
        environment.endpoints.customer_consultation.init,
      {
        params: new CustomHttpParams("api-pj-public-token"),
      },
    );
  }

  getUserInfo(nickname: string): Observable<any> {
    return this.http.get(
      environment.api_key.publicPJ.back_url +
        environment.endpoints.user_customer.init +
        `?companyId=${nickname.split("-")[0]}&userId=${nickname.split("-")[1]}`,
      {
        params: new CustomHttpParams("api-pj-public-token"),
      },
    );
  }

  /**
   * get Movements dashboard
   * @param data data Request Params
   */
  getMovementsDashboard(
    data: IMovementsDashboardRequest,
  ): Observable<IResponseServices | any> {
    const services = data.services
      .map((service) => `service=${service}`)
      .join("&");
    const params = `?personId=${
      data.personId ? data.personId.split("-")[0] : ""
    }&account=${data.account}&dateStart=${data.dateStart}&dateEnd=${
      data.dateEnd
    }&${services}`;
    return this.http.get(
      environment.api_key.payments.back_url +
        environment.endpoints.movementsDashboard.init +
        params,
      {
        params: new CustomHttpParams("api-pn-payments-apiKey-token-noctype"),
      },
    );
  }

  getAddress(personId?: string) {
    return this.http.get<IAddress>(
      environment.api_key.publicPN.back_url +
        environment.endpoints.users.init +
        environment.endpoints.users.specific.addresses +
        `${personId}`,
      {
        params: new CustomHttpParams("api-pn-public-apiKey-token-noctype"),
      },
    );
  }

  getAllData() {
    return this.http.get<IAllData>(
      environment.api_key.services.back_url +
        environment.endpoints.core.init +
        environment.endpoints.core.specific.customer +
        environment.endpoints.core.specific.get,
      {
        params: new CustomHttpParams("api-token"),
      },
    );
  }
}
