import { ILogin } from "@app/models/all-data";

export const loginsFeatureName = "loginsReducer";

export const initialLoginState: ILogin = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
