import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IPages } from "@app/models/pages";
import { environment } from "@environment";
import { IVehicleInformation } from "@vehicles/models/vehicle";
import { saveAs } from "file-saver";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { take } from "rxjs/operators";
import { IResponseActiveTAG, ITagActivation } from "../models/tag-activation";
import { ActivationTagService } from "./activation-tag.service";
import { AuthenticationService } from "./authentication.service";
import { StorageService } from "./storage.service";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class CommonsService {
  timePause: number = 2000;

  private policyKey = "policyVersion";

  setPolicyEnabled(enabled: boolean): void {
    localStorage.setItem(this.policyKey, JSON.stringify(enabled));
  }

  getPolicyEnabled(): boolean {
    return JSON.parse(localStorage.getItem(this.policyKey) || "false");
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private appFacade: AppFacade,
    private activationTagService: ActivationTagService,
  ) {}

  /**
   * Logout Method
   */
  async logOut(flag = true, callService = true): Promise<any> {
    if (callService) {
      await this.authenticationService.signOut();
    }
    localStorage.clear();
    await this.storageService.removeAll();
    this.appFacade.resetDataStore();
    if (flag) {
      this.router.navigate(["signin/login"]);
    }
  }

  /**
   * Verify state to return value on guard
   * @param info pages
   * @param stateUrl url
   * @param path path to redirect
   */
  verifyPagesProcess(info: IPages, stateUrl: string, path: string) {
    let existUrl = false;
    if (info.pages) {
      info.pages.forEach((page) => {
        if (`/${path}/${page.active}` === stateUrl) {
          existUrl = true;
        }
      });
      if (existUrl === false) {
        this.router.navigate([`/home`]);
      }
      return existUrl;
    } else {
      this.router.navigate(["/home"]);
      return false;
    }
  }

  async verifyPagesSignProcess(
    info: IPages,
    stateUrl: string,
    path: string,
  ): Promise<boolean> {
    let existUrl = false;
    if (info.pages) {
      info.pages.forEach((page) => {
        if (`/${path}/${page.active}` === stateUrl) {
          existUrl = true;
        }
      });
      if (existUrl === false) {
        this.router.navigate([
          `/${path}/${info.pages[info.pages.length - 1].active}`,
        ]);
      }
      return existUrl;
    } else {
      this.router.navigate([`/${path}`]);
      return false;
    }
  }
  /**
   * get Documents to Download
   * @param urls urls
   */
  async getDocumentDownload(urls: string) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    try {
      const name = urls.split("pdf/")[1];
      const resp: any = await this.http
        .get(urls, {
          headers,
          responseType: "blob" as "json",
        })
        .toPromise();
      const blob = new Blob([resp], {
        type: "application/pdf;charset=utf-8",
      });
      saveAs(blob, name);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Generate promise for each url
   */
  async generatorPromiseDownload(urls: string[]): Promise<any> {
    let documentsDownloadCopy = JSON.parse(JSON.stringify(urls));
    const documents = [];
    documents.push(
      new Promise((resolve, reject) => {
        resolve(this.getDocumentDownload(documentsDownloadCopy[0]));
      }),
    );
    if (urls.length > 1) {
      documentsDownloadCopy = documentsDownloadCopy.splice(1);
      documents.push(
        new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.getDocumentDownload(documentsDownloadCopy[0]));
          }, 2000);
        }),
      );
    }
    const resolvePromises = await Promise.all(documents);

    return this.compareValues(resolvePromises);
  }

  /**
   * Compare answers to all promises
   */
  compareValues(downloads: boolean[]): boolean {
    let index: number = 0;
    for (const download of downloads) {
      download === false ? (index = index - 1) : (index = index + 1);
    }
    return index === -downloads.length ? false : true;
  }
  /**
   * Navigate ti url
   * @param url
   */
  navigate(url: string): void {
    this.router.navigate([url]);
  }

  /**
   * Navigate ti url
   * @param url
   */
  navigateParam(url: string, param: string): void {
    this.router.navigate([url], { queryParams: { param } });
  }

  /**
   * Redirect to principal dashboard administration
   */
  redirectToHome(): void {
    this.router.navigate(["/home"]);
  }

  /**
   * get Ng Chapcha Info
   * @param attemps intentos
   */
  async getTokenNgCaptcha(attemps: number = 1): Promise<any> {
    if (environment.production) {
      sessionStorage.setItem("captcha", environment.termporalCaptcha);
      return;
    }
    let token = sessionStorage.getItem("captcha");
    if (attemps < 5) {
      try {
        token = await this.recaptchaV3Service
          .execute("importantAction")
          .pipe(take(1))
          .toPromise();
        sessionStorage.setItem("captcha", token);
      } catch (error) {
        await this.getTokenNgCaptcha(attemps + 1);
      }
    }
  }

  validationVerificationDigit(nitAdjusted: string): string {
    const lengthAdjusted = 15 - nitAdjusted.length;
    for (let index = 0; index < lengthAdjusted; index++) {
      nitAdjusted = "0" + nitAdjusted;
    }
    let digito: number = 0;
    const multiplicacion = [
      71,
      67,
      59,
      53,
      47,
      43,
      41,
      37,
      29,
      23,
      19,
      17,
      13,
      7,
      3,
    ];
    for (let index = 0; index < nitAdjusted.length; index++) {
      digito =
        digito +
        Number(nitAdjusted.substring(index, index + 1)) * multiplicacion[index];
    }
    let residue = digito % 11;
    switch (residue) {
      case 0:
        residue = 0;
        break;
      case 1:
        residue = 1;
        break;
      default:
        residue = 11 - residue;
        break;
    }
    return residue.toString();
  }

  /**
   * Call tagActivation Service
   * @param tagActivationData tagActivationData
   */
  async callTagActivationService(
    tagActivationData: ITagActivation,
  ): Promise<IResponseActiveTAG> {
    return this.activationTagService
      .postTagActivation(tagActivationData)
      .toPromise();
  }
  /**
   * redirectToTagWithVehicleInformation
   * @param vehicle vehicleinformation
   * @param url url to redirect
   */
  redirectToTagWithVehicleInformation(
    vehicle: IVehicleInformation,
    url: string = "/tag/shipping-address",
  ): void {
    const navigationExtras: NavigationExtras = {
      state: {
        ...vehicle,
      },
    };
    this.router.navigate([url], navigationExtras);
  }
}
