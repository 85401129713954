import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { IAccounts } from "@app/models/all-data";
import { Http } from "@app/modules/account/services/http-client/HttpClient";
import { environment } from "@environment";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { IChangeAccountModality } from "../../models/account-modality";
import { AccountModalityTypes } from "../../models/account-modality.enum";

@Injectable({
  providedIn: "root",
})
export class AccountRequestsService {
  private currentDataSubject = new BehaviorSubject<IAccounts>(null);
  currentData$: Observable<IAccounts> = this.currentDataSubject.asObservable();

  /**
   * Constructor
   * @param http HttpClient
   * @param encryptApiService EncryptApiService
   */
  constructor(private http: HttpClient) {}

  /**
   * Get urls tokenization
   */
  getUrlsTokenization() {
    return this.http.post(
      environment.api_key.services.back_url +
        environment.endpoints.tokenization.init +
        environment.endpoints.tokenization.specific.urls,
      {},
      {
        params: new CustomHttpParams("api-pj-public-token"),
      },
    );
  }

  /**
   * Get cards
   * @param accountId string
   */
  getCards(accountId?: string) {
    const queryParam =
      accountId && accountId !== "" ? `?account=${accountId}` : "";
    return this.http.get(
      environment.api_key.services.back_url +
        environment.endpoints.cards.init +
        environment.endpoints.cards.specific.cards +
        queryParam,
      {
        params: new CustomHttpParams("api-pj-public-token"),
      },
    );
  }

  /**
   * Delete card
   * @param id string
   */
  deleteCard(id: string) {
    return this.http.delete(
      environment.api_key.services.back_url +
        environment.endpoints.cards.init +
        environment.endpoints.cards.specific.cards +
        "/" +
        id,
      {
        params: new CustomHttpParams("api-pj-public-token"),
      },
    );
  }

  /**
   * Validate card reference
   * @param ref string
   */
  async validateCardReference(ref: string) {
    const result = await Http.consumeService({
      url:
        environment.api_key.services.back_url +
        environment.endpoints.validateReference.init +
        environment.endpoints.validateReference.specific.validate,
      method: "POST",
      body: { ref },
    });

    return of(result);
  }

  /**
   * Activate automatic recharge
   * @param data IChangeAccountModality
   * @param update boolean
   */
  async changeModality(data: IChangeAccountModality, update = false) {
    const payload = {
      accountType: data.modality,
      account: data.accountId,
    };
    if (data.modality === AccountModalityTypes.AUTOMATIC) {
      payload["lowBalance"] = data.activeRechargeValue;
      payload["rechargeBalance"] = data.rechargeValue;
      payload["idPaymentMethod"] = data.refCard;
      if (update) {
        payload["isUpdateRechargeValue"] = true;
        delete payload["idPaymentMethod"];
      }
    }

    const result = await Http.consumeService({
      url:
        environment.api_key.services.back_url +
        environment.endpoints.changeModality.init +
        environment.endpoints.changeModality.specific.modality,
      method: "PUT",
      body: payload,
    });

    return of(result);
  }

  /**
   * Change paid method
   * @param data IChangeAccountModality
   */
  async changePaidMethod(
    data: IChangeAccountModality,
  ): Promise<Observable<{ status: any; data: any }>> {
    const payload = {
      account: data.accountId,
      idPaymentMethod: data.refCard,
    };

    const result = await Http.consumeService({
      url:
        environment.api_key.services.back_url +
        environment.endpoints.changeModality.init +
        environment.endpoints.changeModality.specific.paymentMethod,
      method: "PATCH",
      body: payload,
    });

    return of(result);
  }

  /**
   * Get status accounts
   * @param payload { accounts: string[] }
   */
  getStatusAccounts(payload: {
    accounts: string[];
  }): Observable<{ status: any; data: any }> {
    return from(
      Http.consumeService({
        url:
          environment.api_key.services.back_url +
          environment.endpoints.statusAccount.init +
          environment.endpoints.statusAccount.specific.status,
        method: "POST",
        body: payload,
      }),
    );
  }

  changeData(data: IAccounts) {
    this.currentDataSubject.next(data);
  }
}
