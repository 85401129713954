import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";
import {
  IDeleteVehicleRequest,
  IValidateVehicleRequest,
  IVehiclesListRequest,
  IVehiclesRequest,
  IVehicleUpdateRequest,
  IVehicleCreation,
} from "../models/vehicle";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class VehiclesService {
  constructor(private http: HttpClient) {}
  /**
   * Endpoint to get vehicles categories
   */
  getVehiclesCategories(): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.vehicles.init +
        environment.endpoints.vehicles.specific.categories +
        "?category_enabled=true",
      {
        params: new CustomHttpParams("api-pn-public-apiKey"),
      },
    );
  }
  /**
   * end point to create vehicle
   * @param data vehicle data
   */
  postVehicle(data: IVehicleCreation): Observable<any> {
    return this.http.post(
      environment.api_key.vehicles.back_url +
        environment.endpoints.vehicles.init +
        environment.endpoints.vehicles.specific.create,
      data,
      {
        params: new CustomHttpParams("api-pn-vehicles-apiKey-token"),
      },
    );
  }
  /**
   * get Vehicles List
   * @param data vehicle List Request Data
   */
  getVehiclesList(data: IVehiclesListRequest): Observable<any> {
    let params = [
      data.licensePlate ? `licensePlate=${data.licensePlate}` : "",
      data.personId ? `personId=${data.personId.split("-")[0]}` : "",
      data.pageSize ? `limit=${data.pageSize}` : "",
      data.lastEvaluatedKey
        ? `lastEvaluatedKey=${btoa(JSON.stringify(data.lastEvaluatedKey))}`
        : "",
    ].filter((param) => param !== "");

    let queryString = params.join("&");
    return this.http.get(
      `${environment.api_key.vehicles.back_url}${environment.endpoints.vehicles.v2}${environment.endpoints.vehicles.init}?${queryString}`,
      {
        params: new CustomHttpParams("api-token"),
      },
    );
  }

  /**
   * get all vehicles
   * @param personId string
   */
  getAllVehicles(personId: string): Observable<any> {
    return this.http.get(
      `${environment.api_key.vehicles.back_url}${
        environment.endpoints.vehicles.v2
      }${environment.endpoints.vehicles.init}?personId=${
        personId.split("-")[0]
      }`,
      {
        params: new CustomHttpParams("api-token"),
      },
    );
  }

  /**
   *
   * @param data IDeleteVehicleRequest
   * @returns Observable
   */
  deleteVehicle(data: IDeleteVehicleRequest): Observable<any> {
    return this.http.post(
      environment.api_key.vehicles.back_url +
        environment.endpoints.vehicles.init2 +
        environment.endpoints.vehicles.specific.delete,
      data,
      {
        params: new CustomHttpParams("api-token-x-client-id"),
      },
    );
  }
  /**
   *
   * @param data IValidateVehicleRequest
   * @returns Observable
   */
  validateVehicle(data: IValidateVehicleRequest): Observable<any> {
    return this.http.post(
      environment.api_key.vehicles.back_url +
        environment.endpoints.vehicles.init2 +
        environment.endpoints.vehicles.specific.validate,
      data,
      {
        params: new CustomHttpParams("api-token"),
      },
    );
  }

  /**
   *
   * @param data IVehiclesRequest
   * @returns Observable
   */
  createVehicle(data: IVehiclesRequest): Observable<any> {
    return this.http.post(
      environment.api_key.vehicles.back_url +
        environment.endpoints.workflows.init +
        environment.endpoints.workflows.specific.vehicleEnt +
        environment.endpoints.workflows.specific.create,
      data,
      {
        params: new CustomHttpParams("api-token"),
      },
    );
  }

  update(data: IVehicleUpdateRequest): Observable<any> {
    return this.http.post(
      environment.api_key.vehicles.back_url +
        environment.endpoints.vehicles.init2 +
        environment.endpoints.vehicles.specific.update,
      data,
      {
        params: new CustomHttpParams("api-token"),
      },
    );
  }
}
