import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { AppFacade } from "@app/app.facade";
import { IAccounts } from "@app/models/all-data";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { Languages } from "@app/store/actions/app.actions";
import { environment } from "@environment";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IReportDetail, IReportTypeResponse } from "../../models/report";
import { ReportsNavigationService } from "../../services/reports-navigation.service";
import { RequesReportService } from "../../services/reques-report.service";

@Component({
  selector: "fp-request-report",
  templateUrl: "./request-report.component.html",
  styleUrls: ["./request-report.component.scss"],
})
export class RequestReportComponent implements OnInit, OnDestroy {
  formRequest: UntypedFormGroup;
  reportType: string = "";
  reportFormat: string = "";
  reportFormatList = ["excel", "pdf", "text"];
  months: string[];
  years: number[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  maxDateLimit: moment.Moment;
  minDateLimit: moment.Moment;
  dateRange: any;
  selectedMonth: number;
  selectedYear: number;
  reportTypeFormat = environment.reportsFileType;
  reportTypePermission = environment.reportType;
  loadingReport: boolean = false;
  oneClickRequestReport: boolean = true;
  reportLapse: number = +environment.reportLapse;

  reportsType = [];

  @ViewChild("monthSelect") monthSelect: MatSelect;
  @ViewChild("yeartSelect") yearSelect: MatSelect;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public appFacade: AppFacade,
    private commonsService: CommonsService,
    private requesReportService: RequesReportService,
    private reportsNavigationService: ReportsNavigationService,
    private http: HttpClient,
  ) {
    this.selectLanguaje$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
  }

  ngOnInit(): void {
    this.loadReportTypes();
    this.setLimitDates();
    this.initForm();
    this.setLimitDateReportRequest();
  }

  loadReportTypes() {
    this.http
      .get("assets/i18n/reports/request/es.json")
      .subscribe((data: IReportTypeResponse) => {
        this.reportsType = data.reports.filter(
          (report: IReportDetail) => report.enabled,
        );
      });
  }

  setLimitDateReportRequest() {
    const limitMonth = new Date().getMonth() - this.reportLapse;
    let currentYear = new Date().getFullYear() + 1;
    this.months = Array.from({ length: this.reportLapse }, (item, i) => {
      return new Date(0, i + limitMonth)
        .toLocaleString("default", { month: "short" })
        .replace(".", "");
    });
    this.years =
      limitMonth >= 9
        ? [...Array(2).keys()].map(() => currentYear--)
        : [currentYear - 1];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initForm() {
    this.formRequest = this.formBuilder.group({
      reportType: new UntypedFormControl("", [Validators.required]),
      reportFormatexcel: new UntypedFormControl("excel", [Validators.required]),
      reportFormatpdf: new UntypedFormControl("", [Validators.required]),
      reportFormattext: new UntypedFormControl("", [Validators.required]),
      month: new UntypedFormControl("", [Validators.required]),
      year: new UntypedFormControl("", [Validators.required]),
    });
  }

  chooseDefaultType() {
    this.formRequest.controls.reportType.setValue("movement");
    this.reportType = "movement";
  }

  setLimitDates() {
    const date = new Date();
    this.maxDateLimit = moment(date);
    date.setMonth(date.getMonth() - this.reportLapse);
    this.minDateLimit = moment(date);
  }

  chooseReportType(type: string) {
    this.formRequest.controls.reportType.setValue(type);
    this.reportType = type;
    this.reportFormat = "";
    this.dateRange = null;
    this.formRequest.controls.reportFormatpdf.reset();
    this.formRequest.controls.reportFormattext.reset();
    this.formRequest.controls.reportFormatexcel.reset();
    this.formRequest.controls.month.reset();
    this.formRequest.controls.year.reset();
  }

  chooseReportFormat(selectedFormat: any) {
    this.reportFormat = selectedFormat;
    switch (selectedFormat) {
      case "xlsx":
        this.formRequest.controls.reportFormatexcel.value
          ? this.formRequest.controls.reportFormatexcel.setValue("")
          : this.formRequest.controls.reportFormatexcel.setValue(
              this.reportFormat,
            );

        break;
      case "pdf":
        this.formRequest.controls.reportFormatpdf.value
          ? this.formRequest.controls.reportFormatpdf.setValue("")
          : this.formRequest.controls.reportFormatpdf.setValue(
              this.reportFormat,
            );
        break;
      case "text":
        this.formRequest.controls.reportFormattext.value
          ? this.formRequest.controls.reportFormattext.setValue("")
          : this.formRequest.controls.reportFormattext.setValue(
              this.reportFormat,
            );
        break;
    }
  }

  saveReportMonth(value: string) {
    this.selectedMonth = +moment()
      .month(value)
      .format("M");
  }
  saveReportYear(event: any) {
    this.selectedYear = event.value;
  }

  async requestReport() {
    let dateStart: string;
    let dateEnd: string;
    const promiseAccountSelected = await this.selectAccountSelected$
      .pipe(take(1))
      .toPromise();
    try {
      this.loadingReport = true;
      this.oneClickRequestReport = false;
      const type = parseInt(this.formRequest.controls["reportType"].value);
      if (this.dateRange) {
        dateStart = moment(this.dateRange.startDate).format("YYYY-MM-DD");
        dateEnd = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      } else {
        dateStart = moment(
          new Date(this.selectedYear, this.selectedMonth - 1, 1, 0, 0, 0),
        ).format("YYYY-MM-DD");
        dateEnd = moment(
          new Date(this.selectedYear, this.selectedMonth, 0, 23, 59, 0),
        ).format("YYYY-MM-DD");
      }

      const result = await this.requesReportService
        .setRequestReport(
          type,
          "xlsx",
          dateStart,
          dateEnd,
          promiseAccountSelected.accountId.toString(),
        )
        .toPromise();
      if (result.message === "Error al realizar la solicitud de reporte") {
        this.commonsService.navigate("/error");
      } else {
        this.reportsNavigationService.next(1);
      }
      this.loadingReport = false;
      this.oneClickRequestReport = true;
    } catch (error) {
      this.commonsService.navigate("/error");
      this.loadingReport = false;
      this.oneClickRequestReport = true;
    }
  }

  selectedDateHandler(payload: any) {
    this.dateRange = payload;
  }

  formRequestValidator(): boolean {
    return (
      this.formRequest.controls.reportType.value &&
      this.formRequest.controls.reportFormatexcel.value &&
      this.dateRange
    );
  }

  get selectLanguaje$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  get selectAccountSelected$(): Observable<IAccounts> {
    return this.appFacade.selectAccountSelected$;
  }
}
