import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { ILogin, ILoginsData, IUserData } from "@app/models/all-data";
import { IPseBanks } from "@app/models/pse";
import { IUserInfo } from "@app/models/user-info";
import { AuthenticationService } from "@shared/services/authentication.service";
import { CommonsService } from "@shared/services/commons.service";
import { initialModulesResetState } from "@store/state/modules.state";
import { PaymentFacade } from "@theme/components/payment/store/facade/payment.facade";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IMinimumAccount } from "../shared-financial-data/models/financial-data";
import { SharedFinancialDataFacade } from "../shared-financial-data/store/facade/shared-financial-data.facade";

@Component({
  selector: "fp-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  userInfo: IUserData;
  MNUVEH: boolean;
  loginData: ILoginsData;
  constructor(
    private appFacade: AppFacade,
    private authenticationService: AuthenticationService,
    private commonService: CommonsService,
    private sharedFinancialDataFacade: SharedFinancialDataFacade,
    private paymentFacade: PaymentFacade,
    public permissionService: NgxPermissionsService,
  ) {}

  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  /**
   * get Initial State
   */
  async ngOnInit(): Promise<any> {
    this.permissionService.getPermissions();
    this.MNUVEH = await this.permissionService.hasPermission("MNUVEH");
    this.appFacade.getAllData();
    this.appFacade.setModules(initialModulesResetState);
    await this.getUserInfo();
    this.getAnotherValues();

    this.selectLoginsData$.pipe(take(2)).subscribe((log: ILogin) => {
      this.loginData = log.data;
    });
  }

  getAnotherValues(): void {
    this.selectPseBanks$.pipe(take(1)).subscribe((action: any) => {
      if (action && action.data === null) {
        this.paymentFacade.getPseBanks();
      }
    });
    this.selectMinimumAmountPospaid$.pipe(take(1)).subscribe((action: any) => {
      if (action && action.data === null) {
        this.sharedFinancialDataFacade.getMinimumAmountPospaid({
          modality: "POSTPAGO",
          personType: "PERSONA_JURIDICA",
        });
      }
    });
    this.selectMinimumAmountPrepaid$.pipe(take(1)).subscribe((action: any) => {
      if (action && action.data === null) {
        this.sharedFinancialDataFacade.getMinimumAmountPrepaid({
          modality: "PREPAGO",
          personType: "PERSONA_JURIDICA",
        });
      }
    });
  }

  async getUserInfo(): Promise<any> {
    const userInfoPromise = await this.selectUserInfo$
      .pipe(take(1))
      .toPromise();
    if (userInfoPromise?.data === null) {
      this.appFacade.getUserInfo(
        await this.authenticationService.getUsername(),
      );
      this.selectUserInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((action: IUserInfo) => {
          if (action && action.data !== null) {
            this.userInfo = action.data;
          }
          if (action && action.error) {
            this.commonService.navigate("error");
          }
        });
    } else {
      this.userInfo = userInfoPromise.data;
    }
  }

  get selectUserInfo$(): Observable<IUserInfo> {
    return this.appFacade.selectUserInfo$;
  }

  get selectPseBanks$(): Observable<IPseBanks> {
    return this.paymentFacade.selectPseBanks$;
  }

  get selectMinimumAmountPospaid$(): Observable<IMinimumAccount> {
    return this.sharedFinancialDataFacade.selectMinimumAmountPospaid$;
  }

  get selectMinimumAmountPrepaid$(): Observable<IMinimumAccount> {
    return this.sharedFinancialDataFacade.selectMinimumAmountPrepaid$;
  }

  get selectLoginsData$(): Observable<ILogin> {
    return this.appFacade.selectLoginsData$;
  }
}
